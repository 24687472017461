import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { NavLink, useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Settings from '../../assets/images/settings.png';
import Logo from '../../assets/images/safa_logo.png';

import { changeLanguage } from "../../store/actions/language";

import './Toolbar.css';

const Toolbar = (props) => {
    const { language, isAuthenticated, onChangeLanguage } = props;
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, Settings) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const changeLanguageHandler = () => {
        console.log(language.language);
        let payload = {
            language: language.language === 'ar' ? 'en' : 'ar'
        };

        onChangeLanguage(payload);
    };

    return (
        <React.Fragment>
            <div className="ToolbarContainer">
                <div className={'ToolbarSettingsContainer'}>
                    <Button
                        className={'ToolbarSettings-Current'}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <img src={Settings} alt="Settings" className="Toolbar-Settings" />
                    </Button>
                    <Popper className="ToolbarSettings-SettingsMenu" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            <MenuItem className="ToolbarSettings-SettingsMenuItem" onClick={(event) => handleClose(event)}>
                                                <Typography component={'span'} variant={'body2'} className="ToolbarSettings-SettingsMenuItemTitle">{language.Toolbar.toolbar_change_password}</Typography>
                                            </MenuItem>
                                            <MenuItem className="ToolbarSettings-SettingsMenuItem" onClick={(event) => handleClose(event)}>
                                                <NavLink to='/logout' className="ToolbarSettings-MenuLink" >
                                                    <Typography component={'span'} variant={'body2'} className="ToolbarSettings-SettingsMenuItemTitle">{language.Toolbar.toolbar_logout}</Typography>
                                                </NavLink>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                    <div className="ToolbarLanguage">
                        <Typography component={'span'} variant={'body2'} onClick={changeLanguageHandler} className="ToolbarLanguageTitle">{language.Toolbar.toolbar_language}</Typography>
                    </div>
                </div>
                <div className={'ToolbarLogoContainer'}>
                    <img src={Logo} alt="Logo" className="Toolbar-Logo" />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeLanguage: (payload) => dispatch(changeLanguage(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);