import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    payload: null,
    error: null,
    status: false,
    showError: false,
    loading: false
};

const verificationCodeStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const verificationCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        status: action.status,
        showError: action.showError,
        error: null,
        loading: false
    });
};

const verificationCodeFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        payload: action.payload,
        status: action.status,
        showError: action.showError,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VERIFICATION_CODE_START: return verificationCodeStart(state, action);
        case actionTypes.VERIFICATION_CODE_SUCCESS: return verificationCodeSuccess(state, action);
        case actionTypes.VERIFICATION_CODE_FAIL: return verificationCodeFail(state, action);
        default: return state;
    }
};

export default reducer;