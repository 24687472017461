import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    country: [],
    message: null, 
    error: [],
    loading: false
};

const fetchCountryStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchCountrySuccess = (state, action) => {
    return updateObject(state, {
        country: action.country,
        error: [],
        loading: false
    });
};

const fetchCountryFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COUNTRY_START: return fetchCountryStart(state, action);
        case actionTypes.FETCH_COUNTRY_SUCCESS: return fetchCountrySuccess(state, action);
        case actionTypes.FETCH_COUNTRY_FAIL: return fetchCountryFail(state, action);
        default: return state;
    }
};

export default reducer;