import React from 'react';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import HotelsList from '../../components/Hotels/Hotels';

const Hotels = props => {
  const { language } = props;

  let HotelsRender = (
    <Aux>
     <HotelsList />
    </Aux>
  )

  return (
      <Aux>
          <Toolbar />
          <Header />
          {HotelsRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang
  };
};

export default connect(mapStateToProps)(Hotels);