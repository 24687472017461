import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import ViewHotel from '../../components/Hotels/ViewHotel/ViewHotel';
import Spinner from '../../components/UI/Spinner/Spinner';

import { fetchHotelsByArea } from "../../store/actions/hotels";

const Hotels = props => {
  const { language, lang, loading, hotel, onFetchHotelsByArea } = props;
  const { ID } = useParams();

  useEffect(() => {
    onFetchHotelsByArea(ID);
  }, [ID, lang]);
  
  let ViewHotelRender = (
    <Aux>
     <ViewHotel From={'area'} type="all" HotelSelected={hotel} HotelID={null} AreaID={ID}  />
    </Aux>
  );

  return (
      <Aux>
          <Toolbar />
          <Header />
          {loading ? <Spinner /> : ViewHotelRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang,
    lang: state.language.lang.language,
    hotel: state.hotels.hotel,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchHotelsByArea: (areaID) => dispatch(fetchHotelsByArea(areaID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);