export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkLoginValidation = (yupObject) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    yupObject.addMethod(yupObject.string, "or", function (schemas, msg) {
        return this.test({
            name: "or",
            message: "Please enter your email or mobile number" || msg,
            test: value => {
                if (Array.isArray(schemas) && schemas.length > 1) {
                    const resee = schemas.map(schema => schema.isValidSync(value));
                    return resee.some(res => res);
                } else {
                    throw new TypeError("Schemas is not correct array schema");
                }
            },
            exclusive: false
        });
    });

    return yupObject.object().shape({
        password: yupObject.string()
            .min(8, "Please enter a valid password")
            .max(50, "Please enter a valid password")
            .required("Please enter your password"),
        email: yupObject
            .string()
            .required("Please enter your email or mobile number")
            .or([yupObject.string().email(), yupObject.string().phone("EG", true)])
            .or([yupObject.string().email(), yupObject.string().matches(phoneRegExp)])
    });
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const getNavigationTitle = (accountType, language) => {
    let titleReturn = '';

    switch (accountType) {
        case 'consumer':
            titleReturn = language.Navigation.navigation_consumer;
            break;
        case 'seller':
            titleReturn = language.Navigation.navigation_seller;
            break;
        case 'elite':
            titleReturn = language.Navigation.navigation_elite;
            break;
        default:
            titleReturn = language.Navigation.navigation_seller;
    }

    return titleReturn;
};

export const getCurrencyTitle = (currency, language) => {
    let titleReturn = '';

    switch (currency) {
        case 'EGP':
            titleReturn = language.all.all_egp_currency_title;
            break;
        case 'USD':
            titleReturn = language.all.all_usd_currency_title;
            break;
        case 'EUR':
            titleReturn = language.all.all_eur_currency_title;
            break;
        case 'SAR':
            titleReturn = language.all.all_sar_currency_title;
            break;
        case 'AED':
            titleReturn = language.all.all_aed_currency_title;
            break;
        default:
            titleReturn = language.all.all_egp_currency_title;
    }

    return titleReturn;
};

export const cleanEmpty = (obj) => {
    if (Array.isArray(obj)) {
      return obj
        .map((v) =>
          v && typeof v === "object" && !(v instanceof Date) ? cleanEmpty(v) : v
        )
        .filter((v) => !(v == null));
    } else {
      return Object.entries(obj)
        .map(([k, v]) => [
          k,
          v && typeof v === "object" && !(v instanceof Date) ? cleanEmpty(v) : v
        ])
        .reduce(
          (a, [k, v]) =>
            v == null ||
            v.length == 0 ||
            (Object.keys(v).length === 0 && v.constructor === Object)
              ? a
              : ((a[k] = v), a),
          {}
        );
    }
  };