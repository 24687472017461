import axios from 'axios';

console.log('baseURL',process.env.REACT_APP_BASE_URL);
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept-Language"] = localStorage.getItem("userLanguage");

instance.interceptors.request.use(
    function(request) {
         var user = localStorage.getItem("access_token") || "";
         request.headers.Authorization = "bearer " + user;
         return request;
    },
    function(error) {
         return Promise.reject(error);
    }
);

export default instance;
