import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    allGroups: [],
    groups: [],
    permissions: [],
    group: null,
    last_page: null,
    message: null,
    status: null,   
    error: [],
    loading: false
};

const fetchGroupsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchGroupsSuccess = (state, action) => {
    return updateObject(state, {
        groups: action.groups,
        last_page: action.last_page,
        error: [],
        loading: false
    });
};

const fetchGroupsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const fetchGroupStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchGroupSuccess = (state, action) => {
    return updateObject(state, {
        group: action.group,
        error: [],
        loading: false
    });
};

const fetchGroupFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const fetchAllGroupsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchAllGroupsSuccess = (state, action) => {
    return updateObject(state, {
        allGroups: action.allGroups,
        error: [],
        loading: false
    });
};

const fetchAllGroupsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const fetchPermissionsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchPermissionsSuccess = (state, action) => {
    return updateObject(state, {
        permissions: action.permissions,
        error: [],
        loading: false
    });
};

const fetchPermissionsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const AddGroupStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const AddGroupSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        error: [],
        loading: false
    });
};

const AddGroupFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const resetGroupsInit = (state, action) => {
    return updateObject(state, {
        status: null,
        error: [],
        loading: false
    });
};

const editGroupStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const editGroupSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        error: [],
        loading: false
    });
};

const editGroupFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GROUPS_START: return fetchGroupsStart(state, action);
        case actionTypes.FETCH_GROUPS_SUCCESS: return fetchGroupsSuccess(state, action);
        case actionTypes.FETCH_GROUPS_FAIL: return fetchGroupsFail(state, action);
        case actionTypes.FETCH_GROUP_START: return fetchGroupStart(state, action);
        case actionTypes.FETCH_GROUP_SUCCESS: return fetchGroupSuccess(state, action);
        case actionTypes.FETCH_GROUP_FAIL: return fetchGroupFail(state, action);
        case actionTypes.FETCH_ALL_GROUPS_START: return fetchAllGroupsStart(state, action);
        case actionTypes.FETCH_ALL_GROUPS_SUCCESS: return fetchAllGroupsSuccess(state, action);
        case actionTypes.FETCH_ALL_GROUPS_FAIL: return fetchAllGroupsFail(state, action);
        case actionTypes.FETCH_PERMISSIONS_START: return fetchPermissionsStart(state, action);
        case actionTypes.FETCH_PERMISSIONS_SUCCESS: return fetchPermissionsSuccess(state, action);
        case actionTypes.FETCH_PERMISSIONS_FAIL: return fetchPermissionsFail(state, action);
        case actionTypes.ADD_GROUP_START: return AddGroupStart(state, action);
        case actionTypes.ADD_GROUP_SUCCESS: return AddGroupSuccess(state, action);
        case actionTypes.ADD_GROUP_FAIL: return AddGroupFail(state, action);
        case actionTypes.RESET_GROUPS_INIT: return resetGroupsInit(state, action);
        case actionTypes.EDIT_GROUP_START: return editGroupStart(state, action);
        case actionTypes.EDIT_GROUP_SUCCESS: return editGroupSuccess(state, action);
        case actionTypes.EDIT_GROUP_FAIL: return editGroupFail(state, action);
        default: return state;
    }
};

export default reducer;