import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    adminID: null,
    adminEmail: null,
    is_admin:null,
    permissions: [],
    rememberMe: false,
    rememberPassword: null,
    rememberUserName: null,
    showError: false,
    error: null,
    loading: false
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        adminID: action.adminID,
        adminEmail: action.adminEmail,
        is_admin: action.is_admin,
        permissions: action.permissions,
        rememberMe: action.rememberMe,
        rememberPassword: action.rememberPassword,
        rememberUserName: action.rememberUserName,
        showError: action.showError,
        error: null,
        loading: false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        showError: action.showError,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { 
        token: null, 
        adminID:null,
        adminEmail: null,
        is_admin: null,
        permissions: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default: return state;
    }
};

export default reducer;