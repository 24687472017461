import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import FormControl from '@material-ui/core/FormControl';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DirectionsIcon from '@material-ui/icons/Directions';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Rating from '@material-ui/lab/Rating';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { fetchHotels, resetHotels, changeStatusHotel } from "../../store/actions/hotels";
import { fetchCitiesWithCountry } from "../../store/actions/cities";
import { fetchAreas } from "../../store/actions/areas";

import Spinner from '../UI/Spinner/Spinner';
import Snackbars from '../UI/Snackbars/Snackbars';

import DeleteHotel from './DeleteHotel/DeleteHotel';
import ChangeStatusHotel from './ChangeStatusHotel/ChangeStatusHotel';

import './Hotels.css';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e7f3fd',
        color: '#393737',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
    body: {
        color: '#393737',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Hotels = (props) => {
    const { loading, language, lang, styleDir, statusAPI, message, last_page, cities, areas=[], hotels, onFetchHotels, onFetchCities, onFetchAreas, onResetHotels } = props;

    const firstRender = useRef(false);

    const classes = useStyles();

    const [filter, setFilter] = useState({});
    const [page, setPage] = useState(1);

    const [searchState, setSearchState] = useState();
    const [status, setStatus] = useState('');
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [star, setStar] = useState('');

    const [areasList, setAreasList] = useState([]);

    const [idSelected, setIdSelected] = useState();
    const [titleSelected, setTitleSelected] = useState();
    const [statusSelected, setStatusSelected] = useState();
    const [modelSelected, setModelSelected] = useState();

    const [anchorElDeleteHotel, setAnchorElDeleteHotel] = useState(null);
    const [anchorElChangeStatusHotel, setAnchorElChangeStatusHotel] = useState(null);

    const searchHandler = (prop) => (event) => {
        setFilter({ ...filter, search: event.target.value });
        setSearchState(event.target.value)
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value.target.value);
        console.log(event.target.value.target.value);
        setFilter({ ...filter, status: event.target.value.target.value});
    };

    const handleChangeStar = (event) => {
        setStar(event.target.value.target.value);
        setFilter({ ...filter, stars: event.target.value.target.value});
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value.target.value);
        onFetchAreas(event.target.value.target.value);
        setFilter({ ...filter, city: event.target.value.target.value});
    };

    const handleChangeAreas = (event) => {
        setArea(event.target.value.target.value);
        setFilter({ ...filter, area: event.target.value.target.value});
    };

    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        onFetchHotels(page, filter);
    }, [page, filter, lang]);

    useEffect(() => {
        onFetchCities('966');
    }, [lang]);

    useEffect(() => {
        if(statusAPI === 201){
            setInterval(() => {
                onResetHotels();
              }, 6000);
        }
    }, [statusAPI]);

    useEffect(() => {
        if(firstRender.current){
        setAreasList(areas);
        }
        firstRender.current = true;
    }, [areas]);

    const resetHandleChange = (event) => {
        setSearchState('');
        setStatus('');
        setCity('');
        setArea('');
        setStar('');
        setAreasList([]);
        onFetchHotels(page, {});
    }

    const deleteHotelHandleClick = (event, id, title) => {
        setAnchorElDeleteHotel(event.currentTarget);
        setTitleSelected(title);
        setIdSelected(id);
    };

    const deleteHotelhandleClose = () => {
        setAnchorElDeleteHotel(null);
    };

    const changeStatusHotelHandleClick = (event, id, title, status, model) => {
        setAnchorElChangeStatusHotel(event.currentTarget);
        setTitleSelected(title);
        setIdSelected(id);
        setStatusSelected(status);
        setModelSelected(model);
    }

    const ChangeStatusHotelhandleClose = () => {
        setAnchorElChangeStatusHotel(null);
    };

    const openDeleteHotel = Boolean(anchorElDeleteHotel);
    const idDeleteHotel = openDeleteHotel ? 'mouse-over-popover-DeleteHotel' : undefined;

    const openChangeStatusHotel = Boolean(anchorElChangeStatusHotel);
    const idChangeStatusHotel = openChangeStatusHotel ? 'mouse-over-popover-ChangeStatusHotel' : undefined;

    return (
        <div className="HotelsList-Container">
            <div className="HotelsList-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="HotelsList-HeadTitle">{language.Hotels.hotels_title}</Typography>
            </div>

            <div className={`HotelsList-ReorderContent`}>
                <div className={`HotelsList-HeadContent`}>
                    <div className={`HotelsList-SearchContainer`}>
                        <TextField
                            className={`HotelsList-Input HotelsList-SearchInput`}
                            id="search"
                            name="search"
                            label={<div>
                                {language.Hotels.hotels_search}
                            </div>}
                            variant="outlined"
                            value={searchState}
                            onChange={searchHandler()}
                            placeholder={language.Hotels.hotels_search} />

                        <FormControl
                            className={`HotelsList-Input HotelsList-StatusInput`}
                            variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                <div>
                                    {language.Hotels.hotels_status}
                                </div>
                            </InputLabel>
                            <Select
                                dir={styleDir}
                                lang={lang}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={status}
                                name="status"
                                onChange={selectedOption => {
                                    let event = { target: { name: 'status', value: selectedOption } }
                                    handleChangeStatus(event);
                                }}
                                label={<div>
                                    {language.Hotels.hotels_status}
                                </div>}
                            >
                                <MenuItem className="HotelsList-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                    {language.Hotels.hotels_status}
                                </MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'1'}>{language.Hotels.hotels_active}</MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'0'}>{language.Hotels.hotels_deactivate}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            className={`HotelsList-Input HotelsList-StatusInput`}
                            variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                <div>
                                    {language.Hotels.hotels_city}
                                </div>
                            </InputLabel>
                            <Select
                                dir={styleDir}
                                lang={lang}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={city}
                                name="city"
                                onChange={selectedOption => {
                                    let event = { target: { name: 'city', value: selectedOption } }
                                    handleChangeCity(event);
                                }}
                                label={<div>
                                    {language.Hotels.hotels_city}
                                </div>}
                            >
                                <MenuItem className="HotelsList-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                {language.Hotels.hotels_city}
                                </MenuItem>
                                {cities.map(city => (<MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={city.id}>{city.name}</MenuItem>))}
                            </Select>
                        </FormControl>

                        <FormControl
                            className={`HotelsList-Input HotelsList-StatusInput`}
                            variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                <div>
                                    {language.Hotels.hotels_areas}
                                </div>
                            </InputLabel>
                            <Select
                                dir={styleDir}
                                lang={lang}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={area}
                                name="areas"
                                onChange={selectedOption => {
                                    let event = { target: { name: 'areas', value: selectedOption } }
                                    handleChangeAreas(event);
                                }}
                                label={<div>
                                    {language.Hotels.hotels_areas}
                                </div>}
                            >
                                <MenuItem className="HotelsList-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                {language.Hotels.hotels_areas}
                                </MenuItem>
                                {areasList.map(area => (<MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={area.id}>{area.name}</MenuItem>))}
                            </Select>
                        </FormControl>


                        <FormControl
                            className={`HotelsList-Input HotelsList-StatusInput`}
                            variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                <div>
                                    {language.Hotels.hotels_star}
                                </div>
                            </InputLabel>
                            <Select
                                dir={styleDir}
                                lang={lang}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={star}
                                name="star"
                                onChange={selectedOption => {
                                    let event = { target: { name: 'star', value: selectedOption } }
                                    handleChangeStar(event);
                                }}
                                label={<div>
                                    {language.Hotels.hotels_star}
                                </div>}
                            >
                                <MenuItem className="HotelsList-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                    {language.Hotels.hotels_star}
                                </MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'1'}>{language.Hotels.hotels_star}</MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'2'}>{language.Hotels.hotels_2stars}</MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'3'}>{language.Hotels.hotels_3stars}</MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'4'}>{language.Hotels.hotels_4stars}</MenuItem>
                                <MenuItem className="HotelsList-MenuItem" dir={styleDir} lang={lang} value={'5'}>{language.Hotels.hotels_5stars}</MenuItem>
                            </Select>
                        </FormControl>

                        <div onClick={resetHandleChange} className={`HotelsList-ResetContainer`}>
                            <RefreshIcon />
                            <Typography component={'span'} variant={'body2'} className="HotelsList-CountHeadTitle">{language.Hotels.hotels_reset_filter}</Typography>
                        </div>

                    </div>
                    <div className="HotelsList-NewHotelButton" >
                        <NavLink to={'/admin/hotels/create'} className={"HotelsList-Link"} >
                            <Typography component={'span'} variant={'body2'} className={"HotelsList-LinkNewHotelTitle"}>{language.Hotels.hotels_new_facility}</Typography>
                        </NavLink>
                    </div>
                </div>
                {loading
                    ? <Spinner />
                    : <React.Fragment>
                        <TableContainer className="HotelsList-TableContainer" component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{language.Hotels.hotels_photo}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_id}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_facility_name}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_city}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_area}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_distinace_haram}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_star}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_status}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_review}</StyledTableCell>
                                        <StyledTableCell>{language.Hotels.hotels_actions}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hotels.map((hotel) => (
                                        <StyledTableRow key={hotel.id}>
                                            <StyledTableCell component="th" scope="row">
                                                <Skeleton variant="rect" width={60} height={41} />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.id}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.facility_name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.city}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.area}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.harm_distance}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {hotel.stars}
                                                <Rating name="read-only" value={hotel.stars_value} readOnly max={5} />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                            <span className={`HotelsList-${hotel.active === 1 ? 'Active' : 'Deactivate'}`}>{hotel.active === 1 ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate}</span>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                            {hotel.verified === 1 ? <DoneAllIcon className="HotelsList-DoneAllIcon" /> : <HighlightOffIcon className="HotelsList-HighlightOffIcon" />}
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row">
                                                <div className="HotelsList-Action">
                                                    <NavLink to={'/admin/hotels/view/' + hotel.id } className="HotelsList-ViewButton" >
                                                        <VisibilityIcon /> {language.Hotels.hotels_view}
                                                    </NavLink>
                                                    <NavLink to={'/admin/hotels/edit/' + hotel.id + '/panel1' } className="HotelsList-EditButton" >
                                                        <EditIcon /> {language.Hotels.hotels_edit}
                                                    </NavLink>
                                                    <div className="HotelsList-DirectionButton">
                                                        <DirectionsIcon /> {language.Hotels.hotels_direction}
                                                    </div>
                                                    <div aria-describedby={idDeleteHotel} className="HotelsList-DeleteButton" onClick={(event, ID, Title) => deleteHotelHandleClick(event, hotel.id, hotel.facility_name)}>
                                                        <DeleteOutlineIcon /> {language.Hotels.hotels_delete}
                                                    </div>
                                                    {hotel.active === 0
                                                        ? <div aria-describedby={idChangeStatusHotel} className="HotelsList-ActiveButton" onClick={(event, ID, Title, Status, Model) => changeStatusHotelHandleClick(event, hotel.id, hotel.facility_name, 1, 'active')} >
                                                            <CheckCircleIcon /> {language.Hotels.hotels_active}
                                                        </div>
                                                        : <div aria-describedby={idChangeStatusHotel} className="HotelsList-DeactivateButton" onClick={(event, ID, Title, Status, Model) => changeStatusHotelHandleClick(event, hotel.id, hotel.facility_name, 0, 'Deactive')} >
                                                            <BlockIcon /> {language.Hotels.hotels_deactivate}
                                                        </div>
                                                    }
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="HotelsList-PaginationContainer">
                            {hotels.length > 0
                                ? <Pagination className="HotelsList-PaginationContent" count={last_page} page={page} onChange={pageHandleChange} />
                                : <Typography component={'span'} variant={'body2'} className={"HotelsList-NoData"}>{language.all.all_no_results}</Typography>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>

            <Popover
                className="HotelsList-DeleteHotelContainer"
                id={idDeleteHotel}
                open={openDeleteHotel}
                anchorEl={anchorElDeleteHotel}
                dir={language.dir}
                lang={language.language}
                onClose={deleteHotelhandleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'HotelesList-PaperDeleteHotel'}>
                    <DeleteHotel Closed={deleteHotelhandleClose} HotelTitle={titleSelected} HotelID={idSelected} />
                </div>
            </Popover>

            <Popover
                className="HotelsList-ChangeStatusHotelContainer"
                id={idChangeStatusHotel}
                open={openChangeStatusHotel}
                anchorEl={anchorElChangeStatusHotel}
                dir={language.dir}
                lang={language.language}
                onClose={ChangeStatusHotelhandleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'HotelesList-PaperChangeStatusHotel'}>
                    <ChangeStatusHotel Closed={ChangeStatusHotelhandleClose} HotelTitle={titleSelected} HotelID={idSelected} Model={modelSelected} Status={statusSelected} />
                </div>
            </Popover>
            {statusAPI === 201 || statusAPI === 200
                ? <Snackbars openSnack={true} message={message} errors={null} statusCode={statusAPI} status={'success'} hideDuration={6000} />
                : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        hotels: state.hotels.hotels,
        loading: state.hotels.loading,
        last_page: state.hotels.last_page,
        cities: state.cities.cities,
        areas: state.areas.areas,
        message: state.hotels.message,
        statusAPI: state.hotels.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchHotels: (payload, filter) => dispatch(fetchHotels(payload, filter)),
        onFetchCities: (countryID) => dispatch(fetchCitiesWithCountry(countryID)),
        onResetHotels: () => dispatch(resetHotels()),
        onFetchAreas: (payload) => dispatch(fetchAreas(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);