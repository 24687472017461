import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';
import Spinner from '../../components/UI/Spinner/Spinner';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';

import "./Home.css";

const Home = props => {
  const { language } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInterval(() => {
      setLoading(false);
    }, 2000);

  }, []);


  let homeRender = (
    <Aux>
      <div className="Home-Welcome">Welcome</div>
    </Aux>
  )

  return (
    <Aux>
      <Toolbar />
      <Header />
      {loading ? <Spinner /> : homeRender}
    </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);