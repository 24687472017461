import React, { useEffect }from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import ViewHotelComponent from '../../components/Hotels/ViewHotel/ViewHotel';

import Spinner from '../../components/UI/Spinner/Spinner';

import { showHotel } from "../../store/actions/hotels";

const ViewHotel = props => {
  const { language, lang, loading, hotel, onShowHotel } = props;

  const { ID } = useParams();

  useEffect(() => {
    onShowHotel(ID);
  }, [ID, lang]);

  let ViewHotelRender = (
    <Aux>
     <ViewHotelComponent From={'hotels'} type="all" HotelSelected={hotel} HotelID={ID} AreaID={null}  />
    </Aux>
  )

  return (
      <Aux>
          <Toolbar />
          <Header />
          {loading ? <Spinner /> : ViewHotelRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang,
    lang: state.language.lang.language,
    loading: state.hotels.loading,
    hotel: state.hotels.hotel,    
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowHotel: (hotelID) => dispatch(showHotel(hotelID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHotel);