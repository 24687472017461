import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchFacilityTypeStart = () => {
    return {
        type: actionTypes.FETCH_FACILITY_TYPE_START
    };
};

export const fetchFacilityTypeSuccess = (facilityType) => {
    return {
        type: actionTypes.FETCH_FACILITY_TYPE_SUCCESS,
        facilityType: facilityType
    };
};

export const fetchFacilityTypeFail = (error) => {
    return {
        type: actionTypes.FETCH_FACILITY_TYPE_FAIL,
        error: error
    };
};

export const fetchFacilityType = () => {
    return (dispatch, getState) => {
        dispatch(fetchFacilityTypeStart());
        const url = "/hotels/types";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchFacilityTypeSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchFacilityTypeFail(err.response.data.error));
                }
            });
    };
};