import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import { fetchCities } from "../../store/actions/cities";

import Spinner from '../../components/UI/Spinner/Spinner';

import './Cities.css';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e7f3fd',
        color: '#393737',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
    body: {
        color: '#393737',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Cities = (props) => {
    const { loading, language, lang, styleDir, cities, onFetchCities } = props;

    const classes = useStyles();

    useEffect(() => {
        onFetchCities();
    }, [lang]);

    return (
        <div className="CitiesList-Container">
            <div className="CitiesList-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="CitiesList-HeadTitle">{language.Cities.cities_title}</Typography>
            </div>

            <div className={`CitiesList-ReorderContent`}>
                {loading
                    ? <Spinner />
                    : <React.Fragment>
                        <TableContainer className="CitiesList-TableContainer" component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{language.Cities.cities_id}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_cityName}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_totalHotels}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_reviewedHotels}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_options}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cities.map((city) => (city['Total Hotels'] > 0 ?
                                        <StyledTableRow key={city.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {city.id}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {city.name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {city['Total Hotels']}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {city['Reviewed Hotels']}
                                                <BorderLinearProgress variant="determinate" value={(city['Reviewed Hotels']*100)/city['Total Hotels']} />
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row">
                                                <div className="CitiesList-Action">
                                                    <div className="CitiesList-OpenButton">
                                                        <NavLink to={'/admin/area_hotels/' + city.id}>
                                                        {language.Cities.cities_open}
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        : null
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        cities: state.cities.cities,
        loading: state.cities.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCities: () => dispatch(fetchCities())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cities);