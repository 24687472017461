import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { fetchAllGroups } from "../../../store/actions/groups";
import { addUsers } from "../../../store/actions/users";

import './NewUser.css';

const NewUser = (props) => {
    const { language, Closed, loading, styleDir, lang, statusAPI, allGroups = [], onFetchAllGroups, onAddUser } = props;

    const [checkedIsAdminYes, setCheckedIsAdminYes] = useState(true);
    const [checkedIsAdminNo, setCheckedIsAdminNo] = useState(false);
    const [role, setRole] = useState('');

    const isAdminYesChangeHandler = (event) => {
        setCheckedIsAdminYes(!checkedIsAdminYes);
        setCheckedIsAdminNo(false);
    };

    const isAdminNoChangeHandler = (event) => {
        setCheckedIsAdminNo(!checkedIsAdminNo);
        setCheckedIsAdminYes(false);
    };

    const handleChangeRole = (event) => {
        setRole(event.target.value.target.value);
    };

    useEffect(() => {
        onFetchAllGroups();
    }, []);

    const validationSchema = yup.object({
        name: yup
            .string(language.UsersManagement.usersManagement_valid_fullName)
            .required(language.UsersManagement.usersManagement_empty_fullName),
        phoneNumber: yup.number()
            .typeError(language.UsersManagement.usersManagement_valid_phone)
            .required(language.UsersManagement.usersManagement_empty_phone)
            .test("phoneNumber-check", language.UsersManagement.usersManagement_valid_phone, (mobile) => {
                if (mobile) {
                    let mobilevalue = '+20' + mobile;

                    try {
                        const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
                        const number = phoneUtil.parse(mobilevalue.toString(), 'EG');

                        if (phoneUtil.isValidNumber(number, 'EG')) {
                            return true;
                        } else {
                            return false;
                        }
                    } catch (e) {
                        console.log('Valid Number was thrown: ', e.toString());
                    }
                }
            }),
        email: yup
            .string(language.UsersManagement.usersManagement_valid_email)
            .email(language.UsersManagement.usersManagement_valid_email)
            .max(50, language.UsersManagement.usersManagement_valid_email)
            .required(language.UsersManagement.usersManagement_empty_email)
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            phoneNumber: '',
            email: '',
            role: '',
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {

            let payload = {
                name: values.name,
                phone_number: values.phoneNumber,
                email: values.email,
                group_id: values.role,
                is_admin: checkedIsAdminYes ? 1 : 0
            };
            console.log(payload);
            addUser(payload);
        },
    });

    const addUser = async (payload) => {
        await onAddUser(payload);
        if (statusAPI === 201) {
            Closed();
        }
    }

    return (
        <React.Fragment>
            <div className="NewUserContainer">
                <div className="NewUser-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="NewUser-HeadTitle">{language.UsersManagement.usersManagement_new_user}</Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="NewUserLine"></div>
                <div className="NewUserFormContainer">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="NewUserFormContent">

                            <TextField
                                className={`NewUserInput ${formik.touched.name && formik.errors.name ? 'NewUserFormInputError' : ''}`}
                                id="name"
                                name="name"
                                label={<div>
                                    {language.UsersManagement.usersManagement_fullName}
                                    <span className="NewUserInputRequired">*</span>
                                </div>}
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={language.UsersManagement.usersManagement_fullName} />

                            <TextField
                                className={`NewUserInput ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'NewUserFormInputError' : ''}`}
                                id="phoneNumber"
                                name="phoneNumber"
                                label={<div>
                                    {language.UsersManagement.usersManagement_phoneNumber}
                                    <span className="NewUserInputRequired">*</span>
                                </div>}
                                variant="outlined"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                inputProps={{ maxLength: 11 }}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                placeholder={language.UsersManagement.usersManagement_phoneNumber} />

                            <TextField
                                className={`NewUserInput ${formik.touched.email && formik.errors.email ? 'NewUserFormInputError' : ''}`}
                                id="email"
                                name="email"
                                label={<div>
                                    {language.UsersManagement.usersManagement_emailAddress}
                                    <span className="NewUserInputRequired">*</span>
                                </div>}
                                variant="outlined"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                placeholder={language.UsersManagement.usersManagement_emailAddress} />

                            <div className="NewUser-CheckboxContainer">
                                <Typography component={'span'} variant={'body2'} className="NewUser-CheckboxTitle">
                                    {language.UsersManagement.usersManagement_isAdmin}
                                </Typography>
                                <FormControl className="NewUser-Checkbox">
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedIsAdminYes} onChange={isAdminYesChangeHandler} name="isAdmin_yes" />}
                                        label={language.UsersManagement.usersManagement_yes}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedIsAdminNo} onChange={isAdminNoChangeHandler} name="isAdmin_no" />}
                                        label={language.UsersManagement.usersManagement_no}
                                    />
                                </FormControl>
                            </div>

                            {checkedIsAdminNo
                                ? <FormControl
                                    className={`NewUserInput  ${formik.touched.productType && formik.errors.productType ? 'NewUserFormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                            {language.UsersManagement.usersManagement_role}
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={role}
                                        name="role"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'role', value: selectedOption } }
                                            handleChangeRole(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.UsersManagement.usersManagement_role}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                            {language.UsersManagement.usersManagement_choose_role}
                                        </MenuItem>
                                        {allGroups.map(group => (
                                            <MenuItem key={group.id} dir={styleDir} lang={lang} value={group.id}>{group.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.productType && Boolean(formik.errors.productType)
                                        ? <FormHelperText className={'NewUserFormInputError'} id="component-error-text">{formik.errors.productType}</FormHelperText>
                                        : null}
                                </FormControl>

                                : null
                            }
                        </div>

                        <div className="NewUserFormFooter">
                            <Button disabled={loading} className="NewUserNewUserButton" type="submit">
                                {loading ? <SpinnerButton /> : language.UsersManagement.usersManagement_new_user_button}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.users.loading,
        allGroups: state.groups.allGroups,
        statusAPI: state.users.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAllGroups: () => dispatch(fetchAllGroups()),
        onAddUser: (payload) => dispatch(addUsers(payload))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewUser));