import * as actionTypes from './actionTypes';

const changelanguageAct = (payload) => {
  return {
    type: actionTypes.LANGUAGE,
    payload: payload,
  };
};

export const changeLanguage = (payload) => {
  localStorage.setItem('userLanguage', payload.language);
  return (dispatch, getState) => {
    dispatch(changelanguageAct(payload));
    if (
      payload.page !== 'AppSettings' &&
      payload.page !== 'DefaultSettings' &&
      !localStorage.getItem('PreviousPage')
    ) {
    }
  };
};

export const Refresh = (payload) => {
  return {
    type: actionTypes.REFRESH,
    payload: payload,
  };
};
