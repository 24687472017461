import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { changeStatusHotel } from "../../../store/actions/hotels";

import './ChangeStatusHotel.css';

const ChangeStatusHotel = (props) => {
    const { language, Closed, loading, HotelTitle, HotelID, styleDir, lang, statusAPI, onChangeStatusHotel, Model, Status } = props;

    useEffect(() => {
        if(statusAPI === 201){
            Closed();
        }
    }, [statusAPI]);

    const ChangeStatusHotelHandleClick = () => {
        onChangeStatusHotel(HotelID, Status);
    }

    return (
        <React.Fragment>
            <div className="ChangeStatusHotelContainer">
                <div className="ChangeStatusHotel-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="ChangeStatusHotel-HeadTitle">{Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate}<span className="ChangeStatusHotel-SubHeadTitle">{' '+HotelTitle}</span></Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="ChangeStatusHotelLine"></div>
                <div className="ChangeStatusHotelFormContainer">
                    <div className="ChangeStatusHotelFormContent">
                        <Typography component={'span'} variant={'body2'} className="ChangeStatusHotel-ContentTitle">{language.Hotels.hotels_sure}{' '}{Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate}{' '}<span className="ChangeStatusHotel-SubContentTitle">{HotelTitle}</span>{' ?'}</Typography>
                    </div>
                    <div className="ChangeStatusHotelFormFooter">
                        <Button  className="ChangeStatusHotelCancelButton" onClick={Closed}>
                        {language.all.all_cancel_btn_title}
                        </Button>

                        <Button disabled={loading} className={Model === 'active' ? 'ChangeStatusHotelButton-active' : 'ChangeStatusHotelButton-Deactive'} onClick={ChangeStatusHotelHandleClick}>
                            {loading ? <SpinnerButton /> : (Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate)}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.hotels.loading,
        statusAPI: state.hotels.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeStatusHotel: (ID, Status) => dispatch(changeStatusHotel(ID, Status))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeStatusHotel));