import React, { useState } from "react";
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { ResetNoInternet } from "../../../store/actions/NoInternet";

import "./NoInternetMessage.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NoInternetMessage = (props) => {
  const { message, open, language, onResetNoInternet } = props;

  const [openState, setOpenState] = useState(open);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onResetNoInternet();
    setOpenState(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: language === 'ar' ? 'right' : 'left',
      }}
      open={openState}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = state => {
  return {
    language: state.language.lang.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onResetNoInternet: () => dispatch(ResetNoInternet()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoInternetMessage);