import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchCountryStart = () => {
    return {
        type: actionTypes.FETCH_COUNTRY_START
    };
};

export const fetchCountrySuccess = (country) => {
    return {
        type: actionTypes.FETCH_COUNTRY_SUCCESS,
        country: country
    };
};

export const fetchCountryFail = (error) => {
    return {
        type: actionTypes.FETCH_COUNTRY_FAIL,
        error: error
    };
};

export const fetchCountry = () => {
    return (dispatch, getState) => {
        dispatch(fetchCountryStart());
        const url = "/lookups/countries";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchCountrySuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchCountryFail(err.response.data.error));
                }
            });
    };
};