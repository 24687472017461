import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import lan from '../localization/language';

const userLanguage = localStorage.getItem('userLanguage');
let DefaultLanguageSettings = {
  lang: lan.en,
  language: 'en',
  styleDir: 'ltr'
};
// const BrowserLanguage = window.navigator.language.slice(0, 2);
// switch (BrowserLanguage) {
//   case 'en':
//     DefaultLanguageSettings.lang = lan.en;
//     DefaultLanguageSettings.language = 'en';
//     DefaultLanguageSettings.styleDir = 'ltr';
//     break;
//   case 'ar':
//     DefaultLanguageSettings.lang = lan.ar;
//     DefaultLanguageSettings.language = 'ar';
//     DefaultLanguageSettings.styleDir = 'rtl';
//     break;
//   default:
//     break;
// }
const initState = {
  lang: userLanguage ? lan[userLanguage] : DefaultLanguageSettings.lang,
  language: userLanguage ? userLanguage : DefaultLanguageSettings.language,
  styleDir: userLanguage
    ? lan[userLanguage].dir
    : DefaultLanguageSettings.styleDir,
};

const changeLanguage = (state, action) => {
  let lang;
  let language;
  let styleDir;

  switch (action.payload.language) {
    case 'en':
      lang = lan.en;
      language = 'en';
      styleDir = 'ltr';
      break;
    case 'ar':
      lang = lan.ar;
      language = 'ar';
      styleDir = 'rtl';
      break;
    default:
      return {};
  }

  return updateObject(state, {
    lang: lang,
    language: language,
    styleDir: styleDir,
  });
};

const Refresh = (state, action) => {
  return updateObject(state, {});
};

const language = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE:
      return changeLanguage(state, action);
    case actionTypes.REFRESH:
      return Refresh(state, action);
    default:
      return state;
  }
};

export default language;
