import React from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import AreasList from '../../components/Areas/Areas';

const Areas = props => {
  const { language } = props;
  const { ID } = useParams();

  let AreasRender = (
    <Aux>
     <AreasList citiesID={ID} />
    </Aux>
  )

  return (
      <Aux>
          <Toolbar />
          <Header />
          {AreasRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang
  };
};

export default connect(mapStateToProps)(Areas);