import React from 'react';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import CitiesList from '../../components/Cities/Cities';

const Cities = props => {
  const { language } = props;

  let CitiesRender = (
    <Aux>
     <CitiesList />
    </Aux>
  )

  return (
      <Aux>
          <Toolbar />
          <Header />
          {CitiesRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang
  };
};

export default connect(mapStateToProps)(Cities);