import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchHotelsStart = () => {
    return {
        type: actionTypes.FETCH_HOTELS_START
    };
};

export const fetchHotelsSuccess = (hotels,last_page) => {
    return {
        type: actionTypes.FETCH_HOTELS_SUCCESS,
        hotels: hotels,
        last_page: last_page
    };
};

export const fetchHotelsFail = (error) => {
    return {
        type: actionTypes.FETCH_HOTELS_FAIL,
        error: error
    };
};

export const fetchHotels = (payload, filter) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsStart());
        const url = "/hotels?page=" + payload;
        axios.get(url,{
            params: cleanEmpty(filter),
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchHotelsSuccess(response.data.data, response.data.meta.last_page));
            })
            .catch(err => {
                console.log(err.response.status);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsFail(err.response.data.message));
                }
            });
    };
};

export const fetchHotelsMapStart = () => {
    return {
        type: actionTypes.FETCH_HOTELS_MAP_START
    };
};

export const fetchHotelsMapSuccess = (hotelsMap) => {
    return {
        type: actionTypes.FETCH_HOTELS_MAP_SUCCESS,
        hotelsMap: hotelsMap
    };
};

export const fetchHotelsMapFail = (error) => {
    return {
        type: actionTypes.FETCH_HOTELS_MAP_FAIL,
        error: error
    };
};

export const fetchHotelsMap = (filter) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsMapStart());
        const url = "/hotels/map";
        axios.get(url,{
            params: cleanEmpty(filter),
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchHotelsMapSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsMapFail(err.response.data.message));
                }
            });
    };
};

export const addHotelStart = () => {
    return {
        type: actionTypes.ADD_HOTEL_START
    };
};

export const addHotelSuccess = (status,message) => {
    return {
        type: actionTypes.ADD_HOTEL_SUCCESS,
        status: status,
        message: message
    };
};

export const addHotelFail = (error, message, status) => {
    return {
        type: actionTypes.ADD_HOTEL_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const addHotel = (payload) => {
    return (dispatch, getState) => {
        dispatch(addHotelStart());
        const url = "/hotels";
        axios.post(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(addHotelSuccess(response.status,response.data.message));
                //dispatch(fetchHotels(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(addHotelFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const resetHotels = () => {
    return {
        type: actionTypes.RESET_HOTELS
    };
};

export const showHotelStart = () => {
    return {
        type: actionTypes.SHOW_HOTEL_START
    };
};

export const showHotelSuccess = (hotel) => {
    return {
        type: actionTypes.SHOW_HOTEL_SUCCESS,
        hotel: hotel
    };
};

export const showHotelFail = (error) => {
    return {
        type: actionTypes.SHOW_HOTEL_FAIL,
        error: error
    };
};

export const showHotel = (HotelID) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsStart());
        const url = "/hotels/" + HotelID;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(showHotelSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(showHotelFail(err.response.data.error));
                }
            });
    };
};

export const deleteHotelStart = () => {
    return {
        type: actionTypes.DELETE_HOTEL_START
    };
};

export const deleteHotelSuccess = (status,message) => {
    return {
        type: actionTypes.DELETE_HOTEL_SUCCESS,
        status: status,
        message: message
    };
};

export const deleteHotelFail = (error, message, status) => {
    return {
        type: actionTypes.DELETE_HOTEL_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const deleteHotel = (hotelID) => {
    return (dispatch, getState) => {
        dispatch(deleteHotelStart());
        const url = "/hotels/"+hotelID;
        axios.delete(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(deleteHotelSuccess(response.status,response.data.message));
                dispatch(fetchHotels(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(deleteHotelFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const changeStatusHotelStart = () => {
    return {
        type: actionTypes.CHANGE_STATUS_HOTEL_START
    };
};

export const changeStatusHotelSuccess = (status,message) => {
    return {
        type: actionTypes.CHANGE_STATUS_HOTEL_SUCCESS,
        status: status,
        message: message
    };
};

export const changeStatusHotelFail = (error, message, status) => {
    return {
        type: actionTypes.CHANGE_STATUS_HOTEL_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const changeStatusHotel = (hotelID, status) => {
    return (dispatch, getState) => {
        dispatch(changeStatusHotelStart());
        const url = "/hotels/active/"+hotelID;

        let payload = {
            active: status
        }

        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(changeStatusHotelSuccess(response.status,response.data.message));
                dispatch(fetchHotels(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(changeStatusHotelFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const changeVerifyHotelStart = () => {
    return {
        type: actionTypes.CHANGE_VERIFY_HOTEL_START
    };
};

export const changeVerifyHotelSuccess = (status,message) => {
    return {
        type: actionTypes.CHANGE_VERIFY_HOTEL_SUCCESS,
        status: status,
        message: message
    };
};

export const changeVerifyHotelFail = (error, message, status) => {
    return {
        type: actionTypes.CHANGE_VERIFY_HOTEL_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const changeVerifyHotel = (hotelID, status) => {
    return (dispatch, getState) => {
        dispatch(changeVerifyHotelStart());
        const url = "/hotels/verify/"+hotelID;

        let payload = {
            verify: status
        }

        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(changeVerifyHotelSuccess(response.status,response.data.message));
                dispatch(showHotel(hotelID));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(changeVerifyHotelFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const fetchHotelsByAreaStart = () => {
    return {
        type: actionTypes.FETCH_HOTELS_BY_AREA_START
    };
};

export const fetchHotelsByAreaSuccess = (hotel) => {
    return {
        type: actionTypes.FETCH_HOTELS_BY_AREA_SUCCESS,
        hotel: hotel
    };
};

export const fetchHotelsByAreaFail = (error) => {
    return {
        type: actionTypes.FETCH_HOTELS_BY_AREA_FAIL,
        error: error
    };
};

export const fetchHotelsByArea = (areaID) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsByAreaStart());
        const url = "/hotels/area/"+ areaID +"/hotel";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response.data.data);
                dispatch(fetchHotelsByAreaSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsByAreaFail(err.response.data.error));
                }
            });
    };
};

export const fetchNextHotelsByArea = (areaID,NextID) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsByAreaStart());
        const url = "/hotels/area/"+ areaID +"/hotel/"+NextID;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response.data.data);
                dispatch(fetchHotelsByAreaSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsByAreaFail(err.response.data.error));
                }
            });
    };
};

export const fetchUnverifyHotelsByArea = (areaID) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsByAreaStart());
        const url = "/hotels/area/"+ areaID +"/hotel?verify=0";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response.data.data);
                dispatch(fetchHotelsByAreaSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsByAreaFail(err.response.data.error));
                }
            });
    };
};

export const fetchNextUnverifyHotelsByArea = (areaID,NextID) => {
    return (dispatch, getState) => {
        dispatch(fetchHotelsByAreaStart());
        const url = "/hotels/area/"+ areaID +"/hotel/"+NextID+"?verify=0";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response.data.data);
                dispatch(fetchHotelsByAreaSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchHotelsByAreaFail(err.response.data.error));
                }
            });
    };
};