import React from 'react';
import { connect } from "react-redux";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import VerificationCodeImg from '../../../assets/images/bg-login.png';

import { verificationCodeAction } from "../../../store/actions/verificationCode";

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";
import ResetPassword from "./ResetPassword/ResetPassword";

import './VerificationCode.css';

const VerificationCode = (props) => {
    const { language, showError, loading, status, userName, onVerificationCodeAction } = props;

    const statusForTesting = true;

    const validationSchema = yup.object({
        verificationCode: yup
            .string()
            .matches(/^[0-9]+$/, language.VerificationCode.verificationCode_code_invalid)
            .min(6, language.VerificationCode.verificationCode_code_invalid)
            .max(6, language.VerificationCode.verificationCode_code_invalid)
            .required(language.VerificationCode.verificationCode_code_required),
    });

    const formik = useFormik({
        initialValues: {
            verificationCode: ''
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            let payload = {
                email: userName,
                token: String(values.verificationCode),
            };
            console.log(payload);
            onVerificationCodeAction(payload);
        },
    });

    return (
        <React.Fragment>
            {status   // status or statusForTesting
                ? <ResetPassword />
                : <div className="VerificationCodeContainer">
                    <div className="VerificationCodeFormContainer">
                        <Typography component={'span'} variant={'body2'} className="VerificationCodeFormTitle">{language.VerificationCode.verificationCode_header_title}</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="VerificationCodeFormContent">
                                <TextField
                                    className={`VerificationCodeInput VerificationCodeVerifyInput ${formik.touched.verificationCode && formik.errors.verificationCode ? 'VerificationCodeFormInputError' : ''}`}
                                    variant="outlined"
                                    id="verificationCode"
                                    name="verificationCode"
                                    type="number"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                                    }}
                                    max={6}
                                    label={<div>
                                        {language.VerificationCode.verificationCode_input_code}
                                        <span className="VerificationCodeInputRequired">*</span>
                                    </div>}
                                    value={formik.values.verificationCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
                                    helperText={formik.touched.verificationCode && formik.errors.verificationCode}
                                />

                                {showError
                                    ? <span className="VerificationCodeShowBackError">{language.VerificationCode.verificationCode_show_back_error}</span>
                                    : <span className="VerificationCodeVisibilityBackError">payload</span>}
                            </div>
                            <div className="VerificationCodeFormFooter">
                                <Button disabled={loading} className="VerificationCodeFormButton" type="submit">
                                    {loading ? <SpinnerButton /> : language.VerificationCode.verificationCode_button_verificationCode}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className="VerificationCodeShowContainer">
                        <img className="VerificationCodeShowImg" src={VerificationCodeImg} alt="VerificationCodeImg" />
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        userName: state.forgotPassword.userName,
        status: state.verificationCode.status,
        showError: state.verificationCode.showError,
        error: state.verificationCode.error,
        loading: state.verificationCode.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVerificationCodeAction: (payload) => dispatch(verificationCodeAction(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);