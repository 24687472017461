import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchCitiesStart = () => {
    return {
        type: actionTypes.FETCH_CITIES_START
    };
};

export const fetchCitiesSuccess = (cities) => {
    return {
        type: actionTypes.FETCH_CITIES_SUCCESS,
        cities: cities
    };
};

export const fetchCitiesFail = (error) => {
    return {
        type: actionTypes.FETCH_CITIES_FAIL,
        error: error
    };
};

export const fetchCities = () => {
    return (dispatch, getState) => {
        dispatch(fetchCitiesStart());
        const url = "/list/cities";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchCitiesSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchCitiesFail(err.response.data.error));
                }
            });
    };
};

export const fetchCitiesWithCountry = (countryID) => {
    return (dispatch, getState) => {
        dispatch(fetchCitiesStart());
        const url = "/lookups/cities?country_id=" + countryID;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchCitiesSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchCitiesFail(err.response.data.error));
                }
            });
    };
};