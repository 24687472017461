import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    users: [],
    last_page: null,
    message: null, 
    status: null,  
    error: [],
    loading: false
};

const fetchUsersStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchUsersSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users,
        last_page: action.last_page,
        error: [],
        loading: false
    });
};

const fetchUsersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const addUsersStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const addUsersSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const addUsersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const editUsersStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const editUsersSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const editUsersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const changePasswordUserStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const changePasswordUserSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const changePasswordUserFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const changeStatusUserStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const changeStatusUserSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const changeStatusUserFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const deleteUserStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const deleteUserSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const deleteUserFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const reSetUsers = (state, action) => {
    return updateObject(state, {
        message: null, 
        status: null,  
        error: [],
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_START: return fetchUsersStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL: return fetchUsersFail(state, action);
        case actionTypes.ADD_USERS_START: return addUsersStart(state, action);
        case actionTypes.ADD_USERS_SUCCESS: return addUsersSuccess(state, action);
        case actionTypes.ADD_USERS_FAIL: return addUsersFail(state, action);
        case actionTypes.EDIT_USERS_START: return editUsersStart(state, action);
        case actionTypes.EDIT_USERS_SUCCESS: return editUsersSuccess(state, action);
        case actionTypes.EDIT_USERS_FAIL: return editUsersFail(state, action);
        case actionTypes.CHANGE_PASSWORD_USER_START: return changePasswordUserStart(state, action);
        case actionTypes.CHANGE_PASSWORD_USER_SUCCESS: return changePasswordUserSuccess(state, action);
        case actionTypes.CHANGE_PASSWORD_USER_FAIL: return changePasswordUserFail(state, action);
        case actionTypes.CHANGE_STATUS_USER_START: return changeStatusUserStart(state, action);
        case actionTypes.CHANGE_STATUS_USER_SUCCESS: return changeStatusUserSuccess(state, action);
        case actionTypes.CHANGE_STATUS_USER_FAIL: return changeStatusUserFail(state, action);
        case actionTypes.DELETE_USER_START: return deleteUserStart(state, action);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
        case actionTypes.DELETE_USER_FAIL: return deleteUserFail(state, action);
        case actionTypes.RESET_USERS: return reSetUsers(state, action);
        default: return state;
    }
};

export default reducer;