import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { deleteUser } from "../../../store/actions/users";

import './DeleteUser.css';

const DeleteUser = (props) => {
    const { language, Closed, loading, UserName, UserID, styleDir, lang, statusAPI, onDeleteUser } = props;

    useEffect(() => {
        if(statusAPI === 200){
            Closed();
        }
    }, [statusAPI]);

    const DeleteUserHandleClick = () => {
        onDeleteUser(UserID);
    }

    return (
        <React.Fragment>
            <div className="DeleteUserContainer">
                <div className="DeleteUser-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="DeleteUser-HeadTitle">{language.Hotels.hotels_delete}<span className="DeleteUser-SubHeadTitle">{' '+UserName}</span></Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="DeleteUserLine"></div>
                <div className="DeleteUserFormContainer">
                    <div className="DeleteUserFormContent">
                        <Typography component={'span'} variant={'body2'} className="DeleteUser-ContentTitle">{language.Hotels.hotels_sure_delete}<span className="DeleteUser-SubContentTitle">{UserName}</span>{' ?'}</Typography>
                    </div>
                    <div className="DeleteUserFormFooter">
                        <Button  className="DeleteUserCancelButton" onClick={Closed}>
                            {language.all.all_cancel_btn_title}
                        </Button>

                        <Button disabled={loading} className="DeleteUserDeleteUserButton" onClick={DeleteUserHandleClick}>
                            {loading ? <SpinnerButton /> : language.Hotels.hotels_delete}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.hotels.loading,
        statusAPI: state.hotels.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteUser: (UserID) => dispatch(deleteUser(UserID)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteUser));