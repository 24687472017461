export const LANGUAGE = "LANGUAGE";
export const REFRESH = "REFRESH";

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const ACCOUNT_START = 'ACCOUNT_START';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAIL = 'ACCOUNT_FAIL';

export const FORGOTPASSWORD_START = 'FORGOTPASSWORD_START';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const FORGOTPASSWORD_FAIL = 'FORGOTPASSWORD_FAIL';

export const VERIFICATION_CODE_START = 'VERIFICATION_CODE_START';
export const VERIFICATION_CODE_SUCCESS = 'VERIFICATION_CODE_SUCCESS';
export const VERIFICATION_CODE_FAIL = 'VERIFICATION_CODE_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const NO_INTERNET = 'NO_INTERNET';
export const RESET_NO_INTERNET = 'RESET_NO_INTERNET';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL';

export const FETCH_ALL_GROUPS_START = 'FETCH_ALL_GROUPS_START';
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ALL_GROUPS_FAIL = 'FETCH_ALL_GROUPS_FAIL';

export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

export const ADD_GROUP_START = 'ADD_GROUP_START';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAIL = 'ADD_GROUP_FAIL';

export const RESET_GROUPS_INIT = 'RESET_GROUPS_INIT';

export const FETCH_GROUP_START = 'FETCH_GROUP_START';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAIL = 'FETCH_GROUP_FAIL';

export const EDIT_GROUP_START = 'EDIT_GROUP_START';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL';

export const ADD_USERS_START = 'ADD_USERS_START';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAIL = 'ADD_USERS_FAIL';

export const EDIT_USERS_START = 'EDIT_USERS_START';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_FAIL = 'EDIT_USERS_FAIL';

export const CHANGE_PASSWORD_USER_START = 'CHANGE_PASSWORD_USER_START';
export const CHANGE_PASSWORD_USER_SUCCESS = 'CHANGE_PASSWORD_USER_SUCCESS';
export const CHANGE_PASSWORD_USER_FAIL = 'CHANGE_PASSWORD_USER_FAIL';

export const CHANGE_STATUS_USER_START = 'CHANGE_STATUS_USER_START';
export const CHANGE_STATUS_USER_SUCCESS = 'CHANGE_STATUS_USER_SUCCESS';
export const CHANGE_STATUS_USER_FAIL = 'CHANGE_STATUS_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RESET_USERS = 'RESET_USERS';

export const FETCH_COUNTRY_START = 'FETCH_COUNTRY_START';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAIL = 'FETCH_COUNTRY_FAIL';

export const FETCH_CITIES_START = 'FETCH_CITIES_START';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAIL = 'FETCH_CITIES_FAIL';

export const FETCH_FACILITY_TYPE_START = 'FETCH_FACILITY_TYPE_START';
export const FETCH_FACILITY_TYPE_SUCCESS = 'FETCH_FACILITY_TYPE_SUCCESS';
export const FETCH_FACILITY_TYPE_FAIL = 'FETCH_FACILITY_TYPE_FAIL';

export const FETCH_AREAS_START = 'FETCH_AREAS_START';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAIL = 'FETCH_AREAS_FAIL';

export const FETCH_HOTELS_START = 'FETCH_HOTELS_START';
export const FETCH_HOTELS_SUCCESS = 'FETCH_HOTELS_SUCCESS';
export const FETCH_HOTELS_FAIL = 'FETCH_HOTELS_FAIL';

export const FETCH_HOTELS_MAP_START = 'FETCH_HOTELS_MAP_START';
export const FETCH_HOTELS_MAP_SUCCESS = 'FETCH_HOTELS_MAP_SUCCESS';
export const FETCH_HOTELS_MAP_FAIL = 'FETCH_HOTELS_MAP_FAIL';

export const FETCH_STARS_START = 'FETCH_STARS_START';
export const FETCH_STARS_SUCCESS = 'FETCH_STARS_SUCCESS';
export const FETCH_STARS_FAIL = 'FETCH_STARS_FAIL';

export const FETCH_FEATURES_START = 'FETCH_FEATURES_START';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_FAIL = 'FETCH_FEATURES_FAIL';

export const ADD_HOTEL_START = 'ADD_HOTEL_START';
export const ADD_HOTEL_SUCCESS = 'ADD_HOTEL_SUCCESS';
export const ADD_HOTEL_FAIL = 'ADD_HOTEL_FAIL';

export const RESET_HOTELS = 'RESET_HOTELS';

export const SHOW_HOTEL_START = 'SHOW_HOTEL_START';
export const SHOW_HOTEL_SUCCESS = 'SHOW_HOTEL_SUCCESS';
export const SHOW_HOTEL_FAIL = 'SHOW_HOTEL_FAIL';

export const DELETE_HOTEL_START = 'DELETE_HOTEL_START';
export const DELETE_HOTEL_SUCCESS = 'DELETE_HOTEL_SUCCESS';
export const DELETE_HOTEL_FAIL = 'DELETE_HOTEL_FAIL';

export const CHANGE_STATUS_HOTEL_START = 'CHANGE_STATUS_HOTEL_START';
export const CHANGE_STATUS_HOTEL_SUCCESS = 'CHANGE_STATUS_HOTEL_SUCCESS';
export const CHANGE_STATUS_HOTEL_FAIL = 'CHANGE_STATUS_HOTEL_FAIL';

export const CHANGE_VERIFY_HOTEL_START = 'CHANGE_VERIFY_HOTEL_START';
export const CHANGE_VERIFY_HOTEL_SUCCESS = 'CHANGE_VERIFY_HOTEL_SUCCESS';
export const CHANGE_VERIFY_HOTEL_FAIL = 'CHANGE_VERIFY_HOTEL_FAIL';

export const FETCH_HOTELS_BY_AREA_START = 'FETCH_HOTELS_BY_AREA_START';
export const FETCH_HOTELS_BY_AREA_SUCCESS = 'FETCH_HOTELS_BY_AREA_SUCCESS';
export const FETCH_HOTELS_BY_AREA_FAIL = 'FETCH_HOTELS_BY_AREA_FAIL';