import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    stars: [],
    features: [],
    message: null, 
    error: [],
    loading: false
};

const fetchStarsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchStarsSuccess = (state, action) => {
    return updateObject(state, {
        stars: action.stars,
        error: [],
        loading: false
    });
};

const fetchStarsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const fetchFeaturesStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchFeaturesSuccess = (state, action) => {
    return updateObject(state, {
        features: action.features,
        error: [],
        loading: false
    });
};

const fetchFeaturesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FEATURES_START: return fetchFeaturesStart(state, action);
        case actionTypes.FETCH_FEATURES_SUCCESS: return fetchFeaturesSuccess(state, action);
        case actionTypes.FETCH_FEATURES_FAIL: return fetchFeaturesFail(state, action);
        case actionTypes.FETCH_STARS_START: return fetchStarsStart(state, action);
        case actionTypes.FETCH_STARS_SUCCESS: return fetchStarsSuccess(state, action);
        case actionTypes.FETCH_STARS_FAIL: return fetchStarsFail(state, action);
        default: return state;
    }
};

export default reducer;