import * as actionTypes from "./actionTypes";

export const NoInternet = () => (dispatch) => {
  try {
    let connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let condition = navigator.connection.effectiveType;

    function updateConnectionStatus() {
      // eslint-disable-next-line no-unused-expressions
      condition === "4g" ? window.location.reload() : "";
    }
    connection.addEventListener("change", updateConnectionStatus);
  } catch (e) {
    // console.log(e);
    window.location.reload();
  }
  dispatch({
    type: actionTypes.NO_INTERNET
  });
};

export const ResetNoInternet = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_NO_INTERNET,
  });
  return actionTypes.RESET_NO_INTERNET;
};
