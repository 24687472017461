import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchStarsStart = () => {
    return {
        type: actionTypes.FETCH_STARS_START
    };
};

export const fetchStarsSuccess = (stars) => {
    return {
        type: actionTypes.FETCH_STARS_SUCCESS,
        stars: stars
    };
};

export const fetchStarsFail = (error) => {
    return {
        type: actionTypes.FETCH_STARS_FAIL,
        error: error
    };
};

export const fetchStars = () => {
    return (dispatch, getState) => {
        dispatch(fetchStarsStart());
        const url = "/hotels/stars";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchStarsSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchStarsFail(err.response.data.error));
                }
            });
    };
};

export const fetchFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_FEATURES_START
    };
};

export const fetchFeaturesSuccess = (features) => {
    return {
        type: actionTypes.FETCH_FEATURES_SUCCESS,
        features: features
    };
};

export const fetchFeaturesFail = (error) => {
    return {
        type: actionTypes.FETCH_FEATURES_FAIL,
        error: error
    };
};

export const fetchFeatures = () => {
    return (dispatch, getState) => {
        dispatch(fetchFeaturesStart());
        const url = "/lookups/features";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchFeaturesSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchFeaturesFail(err.response.data.error));
                }
            });
    };
};