import React, { useState } from 'react';
import { connect } from "react-redux";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import ResetPasswordImg from '../../../../assets/images/bg-login.png';

import { resetPasswordAction } from "../../../../store/actions/resetPassword";

import SpinnerButton from "../../../UI/SpinnerButton/SpinnerButton";

import './ResetPassword.css';

const ResetPassword = (props) => {
    const { language, showError, loading, status, userName, token, onResetPasswordAction } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const validationSchema = yup.object({
        password: yup
            .string(language.ResetPassword.new_password_field_error_msg_invalid_password)
            .min(6, language.ResetPassword.password_field_strength_default_description)
            .max(20, language.ResetPassword.password_field_strength_default_description)
            .required(language.ResetPassword.new_password_field_error_msg_empty),
        confirmPassword: yup
            .string(language.ResetPassword.new_password_field_error_msg_invalid_password)
            .min(6, language.ResetPassword.password_field_strength_default_description)
            .max(20, language.ResetPassword.password_field_strength_default_description)
            .test({
                name: 'passwords-match',
                exclusive: false,
                params: {},
                message: language.ResetPassword.password_field_error_msg_password_doesnt_match,
                test: function (value) {
                    return value === this.parent.password
                },
            })
            .required(language.ResetPassword.new_password_field_error_msg_empty),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            let payload = {
                email: userName,
                token: String(token),
                password: values.password,
            };
            console.log(payload);
            onResetPasswordAction(payload);
        },
    });

    return (
        <React.Fragment>
            <div className="ResetPasswordContainer">
                <div className="ResetPasswordFormContainer">
                    <Typography component={'span'} variant={'body2'} className="ResetPasswordFormTitle">{language.ResetPassword.resetPassword_header_title}</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="ResetPasswordFormContent">
                            <FormControl
                                className={`ResetPasswordInput ResetPassword_PasswordInput ${formik.touched.password && formik.errors.password ? 'ResetPasswordFormInputError' : ''}`}
                                variant="outlined">
                                <InputLabel htmlFor="filled-adornment-password">
                                    <div>
                                        {language.ResetPassword.resetPassword_input_newPassword}
                                        <span className="ResetPasswordInputRequired">*</span>
                                    </div>
                                </InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    placeholder={language.ResetPassword.resetPassword_input_newPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={<div>
                                        {language.ResetPassword.resetPassword_input_newPassword}
                                        <span className="ResetPasswordInputRequired">*</span>
                                    </div>}
                                />
                                {formik.touched.password && Boolean(formik.errors.password)
                                    ? <FormHelperText className={'PasswordInputError'} id="component-error-text">{formik.errors.password}</FormHelperText>
                                    : null}
                            </FormControl>

                            <FormControl
                                className={`ResetPasswordInput ConfirmPasswordInput ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'ResetPasswordFormInputError' : ''}`}
                                variant="outlined">
                                <InputLabel htmlFor="filled-adornment-password">
                                    <div>
                                        {language.ResetPassword.resetPassword_input_confirmPassword}
                                        <span className="ResetPasswordInputRequired">*</span>
                                    </div>
                                </InputLabel>
                                <OutlinedInput
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    placeholder={language.ResetPassword.resetPassword_input_confirmPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={<div>
                                        {language.ResetPassword.resetPassword_input_confirmPassword}
                                        <span className="ResetPasswordInputRequired">*</span>
                                    </div>}
                                />
                                {formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                                    ? <FormHelperText className={'PasswordInputError'} id="component-error-text">{formik.errors.confirmPassword}</FormHelperText>
                                    : null}
                            </FormControl>

                            {showError
                                ? <span className="ResetPasswordShowBackError">{language.ResetPassword.resetPassword_show_back_error}</span>
                                : <span className="ResetPasswordVisibilityBackError">payload</span>}
                        </div>
                        <div className="ResetPasswordFormFooter">
                            <Button disabled={loading} className="ResetPasswordFormButton" type="submit">
                                {loading ? <SpinnerButton /> : language.ResetPassword.resetPassword_button_resetPassword}
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="ResetPasswordShowContainer">
                    <img className="ResetPasswordShowImg" src={ResetPasswordImg} alt="ResetPasswordImg" />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        userName: state.forgotPassword.userName,
        token: state.forgotPassword.payload.token,
        status: state.resetPassword.status,
        showError: state.resetPassword.showError,
        error: state.resetPassword.error,
        loading: state.resetPassword.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPasswordAction: (payload) => dispatch(resetPasswordAction(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);