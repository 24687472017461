import axios from "../../service/axios";
import * as actionTypes from './actionTypes';

export const forgotPasswordStart = () => {
    return {
        type: actionTypes.FORGOTPASSWORD_START
    };
};

export const forgotPasswordSuccess = (payload, showError, status, userName) => {
    return {
        type: actionTypes.FORGOTPASSWORD_SUCCESS,
        payload: payload,
        userName: userName,
        status: status,
        showError: !showError
    };
};

export const forgotPasswordFail = (error) => {
    return {
        type: actionTypes.FORGOTPASSWORD_FAIL,
        payload: error.data,
        status: error.status,
        showError: !error.success,
        error: error.message
    };
};

export const forgotPasswordAction = (payload) => {
    return (dispatch, getState) => {
        dispatch(forgotPasswordStart());

        const url = "/auth/forget-password";

        axios.post(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(forgotPasswordSuccess(response.data.data,response.data.status,response.data.status,payload.email));
            })
            .catch(err => {
                console.log(err.response.data);
                dispatch(forgotPasswordFail(err.response.data));
            });
    };
};