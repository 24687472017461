import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Aux from '../../../hoc/Aux/Aux';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ForgotPassword from '../../../components/ForgotPassword/ForgotPassword';
import NoInternetMessage from "../../../components/UI/NoInternetMessage/NoInternetMessage";

const ForgotPasswordContainer = props => {
  const { language, NoInternetMsg } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInterval(() => {
      setLoading(false);
    }, 2000);
  });

  let NoInternetState = NoInternetMsg ? <NoInternetMessage open={NoInternetMsg} message={language.all.all_no_internet_title} /> : null;

  let loginRender = (
    <Aux>
      <ForgotPassword />
      {NoInternetState}
    </Aux>
  )

  return (
    <Aux>
      {loading ? <Spinner /> : loginRender}
    </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang,
    NoInternetMsg: state.noInternet.NoIntMsg,
  };
};

export default connect(mapStateToProps)(ForgotPasswordContainer);