import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import * as ActionsOfNoInternet from "./NoInternet";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, adminID, adminEmail, is_admin, permissions, showError, rememberMe, rememberUserName, rememberPassword) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        adminID: adminID,
        adminEmail: adminEmail,
        is_admin: is_admin,
        permissions: permissions,
        rememberMe: rememberMe,
        rememberPassword: rememberMe ? rememberPassword : null,
        rememberUserName: rememberMe ? rememberUserName : null,
        showError: !showError
    };
};

export const authFail = (error, showError) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        showError: !showError
    };
};

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('ID');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const auth = (payload, checkedRemember, props) => {
    return (dispatch, getState) => {
        dispatch(authStart());
        const url = "/auth/login";
        axios.post(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response.data);
                const expiresIn = response.data.data.expires_in;
                const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
                localStorage.setItem('access_token', response.data.data.access_token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('ID', response.data.data.id);
                dispatch(
                    authSuccess(
                        response.data.data.access_token,
                        response.data.data.id,
                        payload.email,
                        response.data.data.is_admin,
                        response.data.data.permissions,
                        true,
                        checkedRemember,
                        payload.email,
                        payload.password
                    )
                );
                props.history.push("/home");
            })
            .catch(err => {
                let error = JSON.stringify(err);
                error = JSON.parse(error);
                console.log(error);
                if (error.message === "Network Error") {
                    dispatch(ActionsOfNoInternet.NoInternet());
                    dispatch(authFail('', true));
                } else {
                    dispatch(authFail(err.response.data.error, err.response.data.success));
                }
            });
    };
};