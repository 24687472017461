import axios from "../../service/axios";
import * as actionTypes from './actionTypes';

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    };
};

export const resetPasswordSuccess = (payload, showError, status) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: payload,
        status: status,
        showError: !showError
    };
};

export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        payload: error.data,
        status: error.status,
        showError: !error.success,
        error: error.message
    };
};

export const resetPasswordAction = (payload) => {
    return (dispatch, getState) => {
        dispatch(resetPasswordStart());

        const url = "/auth/reset-password";
        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(resetPasswordSuccess(response.data.data, response.data.status, response.data.status));
            })
            .catch(err => {
                if(err.response === undefined){
                    let failPayload = {
                        data: '',
                        status: false,
                        showError: true,
                        error: 'failed'
                    }
                    dispatch(resetPasswordFail(failPayload));
                }else{
                    console.log(err.response.data);
                    dispatch(resetPasswordFail(err.response.data));
                }
            });
    };
};