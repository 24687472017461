import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { NavLink, useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Settings from '../../assets/images/settings.png';
import Logo from '../../assets/images/safa_logo.png';

import { changeLanguage } from "../../store/actions/language";

import './Header.css';

const Header = (props) => {
    const { language, isAuthenticated, onChangeLanguage } = props;
    const location = useLocation();

    console.log(location);

    return (
        <React.Fragment>
            <div className="HeaderContainer">
                <ul className="Header-LinksList">
                    <li className={location.pathname === '/home' ? "Header-LinksItem-active" : "Header-LinksItem"} >
                        <NavLink to={'/home'} className={location.pathname === '/home' ? "Header-LinksItemLink-active" : "Header-LinksItemLink"} >
                            <Typography component={'span'} variant={'body2'} className={location.pathname === '/home' ? "Header-LinksItemTitle-active" : "HeadersLinksItemTitle"}>{language.Header.header_dashboard}</Typography>
                        </NavLink>
                    </li>

                    <li className={location.pathname === '/admin/hotels' || location.pathname.includes("/admin/hotels/") ? "Header-LinksItem-active" : "Header-LinksItem"} >
                        <NavLink to={'/admin/hotels'} className={location.pathname === '/admin/hotels'  || location.pathname.includes("/admin/hotels/")  ? "Header-LinksItemLink-active" : "Header-LinksItemLink"} >
                            <Typography component={'span'} variant={'body2'} className={location.pathname === '/admin/hotels' || location.pathname.includes("/admin/hotels/")  ? "Header-LinksItemTitle-active" : "HeadersLinksItemTitle"}>{language.Header.header_all_facilities}</Typography>
                        </NavLink>
                    </li>

                    <li className={location.pathname === '/admin/map-explorer' ? "Header-LinksItem-active" : "Header-LinksItem"} >
                        <NavLink to={'/admin/map-explorer'} className={location.pathname === '/admin/map-explorer' ? "Header-LinksItemLink-active" : "Header-LinksItemLink"} >
                            <Typography component={'span'} variant={'body2'} className={location.pathname === '/admin/map-explorer' ? "Header-LinksItemTitle-active" : "HeadersLinksItemTitle"}>{language.Header.header_map_exploler}</Typography>
                        </NavLink>
                    </li>

                    <li className={location.pathname === '/admin/cities' || location.pathname.includes("area") ? "Header-LinksItem-active" : "Header-LinksItem"} >
                        <NavLink to={'/admin/cities'} className={location.pathname === '/admin/cities' || location.pathname.includes("area")  ? "Header-LinksItemLink-active" : "Header-LinksItemLink"} >
                            <Typography component={'span'} variant={'body2'} className={location.pathname === '/admin/cities' || location.pathname.includes("area") ? "Header-LinksItemTitle-active" : "HeadersLinksItemTitle"}>{language.Header.header_list_cities}</Typography>
                        </NavLink>
                    </li>

                    <li className={(location.pathname === '/admin/users' || location.pathname === '/admin/roles') ? "Header-LinksItem-active" : "Header-LinksItem"} >
                        <NavLink to={'/admin/users'} className={(location.pathname === '/admin/users' || location.pathname === '/admin/roles') ? "Header-LinksItemLink-active" : "Header-LinksItemLink"} >
                            <Typography component={'span'} variant={'body2'} className={(location.pathname === '/admin/users' || location.pathname === '/admin/roles') ? "Header-LinksItemTitle-active" : "HeadersLinksItemTitle"}>{language.Header.header_manage_users}</Typography>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        isAuthenticated: state.auth.token !== null
    };
};

export default connect(mapStateToProps)(Header);