import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    };
};

export const fetchUsersSuccess = (users,last_page) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: users,
        last_page: last_page
    };
};

export const fetchUsersFail = (error) => {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        error: error
    };
};

export const fetchUsers = (payload, filter) => {
    return (dispatch, getState) => {
        dispatch(fetchUsersStart());
        const url = "/admins?paginated=" + payload;
        axios.get(url,{
            params: cleanEmpty(filter),
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchUsersSuccess(response.data.data, response.data.meta.last_page));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchUsersFail(err.response.data.error));
                }
            });
    };
};

export const addUsersStart = () => {
    return {
        type: actionTypes.ADD_USERS_START
    };
};

export const addUsersSuccess = (status,message) => {
    return {
        type: actionTypes.ADD_USERS_SUCCESS,
        status: status,
        message: message
    };
};

export const addUsersFail = (error, message, status) => {
    return {
        type: actionTypes.ADD_USERS_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const addUsers = (payload) => {
    return (dispatch, getState) => {
        dispatch(addUsersStart());
        const url = "/admins";
        axios.post(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(addUsersSuccess(response.status,response.data.message));
                dispatch(fetchUsers(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(addUsersFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const editUsersStart = () => {
    return {
        type: actionTypes.EDIT_USERS_START
    };
};

export const editUsersSuccess = (status,message) => {
    return {
        type: actionTypes.EDIT_USERS_SUCCESS,
        status: status,
        message: message
    };
};

export const editUsersFail = (error, message, status) => {
    return {
        type: actionTypes.EDIT_USERS_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const editUsers = (payload, userID) => {
    return (dispatch, getState) => {
        dispatch(editUsersStart());
        const url = "/admins/"+ userID;
        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(editUsersSuccess(response.status,response.data.message));
                dispatch(fetchUsers(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(editUsersFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const changePasswordUserStart = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_USER_START
    };
};

export const changePasswordUserSuccess = (status,message) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_USER_SUCCESS,
        status: status,
        message: message
    };
};

export const changePasswordUserFail = (error, message, status) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_USER_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const changePasswordUser = (payload) => {
    return (dispatch, getState) => {
        dispatch(changePasswordUserStart());
        const url = "/admins/auth/change-password";
        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(changePasswordUserSuccess(response.status,response.data.message));
                dispatch(fetchUsers(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(changePasswordUserFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const changeStatusUserStart = () => {
    return {
        type: actionTypes.CHANGE_STATUS_USER_START
    };
};

export const changeStatusUserSuccess = (status,message) => {
    return {
        type: actionTypes.CHANGE_STATUS_USER_SUCCESS,
        status: status,
        message: message
    };
};

export const changeStatusUserFail = (error, message, status) => {
    return {
        type: actionTypes.CHANGE_STATUS_USER_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const changeStatusUser = (payload, UserID) => {
    return (dispatch, getState) => {
        dispatch(changeStatusUserStart());
        const url = "admins/" + UserID + "/" + payload;
        axios.put(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(changeStatusUserSuccess(response.status,response.data.message));
                dispatch(fetchUsers(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(changeStatusUserFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START
    };
};

export const deleteUserSuccess = (status,message) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        status: status,
        message: message
    };
};

export const deleteUserFail = (error, message, status) => {
    return {
        type: actionTypes.DELETE_USER_FAIL,
        error: error,
        status: status,
        message: message
    };
};

export const deleteUser = (UserID) => {
    return (dispatch, getState) => {
        dispatch(deleteUserStart());
        const url = "admins/" + UserID;
        axios.delete(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(deleteUserSuccess(response.status,response.data.message));
                dispatch(fetchUsers(1,''));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(deleteUserFail(err.response.data.errors, err.response.data.message, err.response.status));
                }
            });
    };
};

export const reSetUsers = () => {
    return {
        type: actionTypes.RESET_USERS
    };
};