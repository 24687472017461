import React from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LoginImg from '../../assets/images/bg-login.png';
import LoginLogo from '../../assets/images/safa_logo.png';

import { forgotPasswordAction } from "../../store/actions/forgotPassword";

import SpinnerButton from "../UI/SpinnerButton/SpinnerButton";
import VerificationCode from './VerificationCode/VerificationCode';

import './ForgotPassword.css';

const ForgotPassword = (props) => {
    const { language, showError, loading, onForgotPasswordAction, status } = props;

    const statusForTesting = true;

    const validationSchema = yup.object({
        email: yup
            .string(language.ForgotPassword.forgotPassword_email_required)
            .test("phoneNumber-check", language.ForgotPassword.forgotPassword_email_valid, (email) => {
                if (email) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                        return true;
                    } else {
                        let mobilevalue = '+20' + email;

                        try {
                            const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
                            const number = phoneUtil.parse(mobilevalue.toString(), 'EG');

                            if (phoneUtil.isValidNumber(number, 'EG')) {
                                return true;
                            } else {
                                return false;
                            }
                        } catch (e) {
                            console.log('Valid Number was thrown: ', e.toString());
                        }
                    }
                }
            })
            .max(50, language.ForgotPassword.forgotPassword_email_valid)
            .required(language.ForgotPassword.forgotPassword_email_required),
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            let payload = {
                email: values.email
            };
            console.log(payload);
            onForgotPasswordAction(payload);
        },
    });

    return (
        <React.Fragment>
            {status   // status or statusForTesting
                ? <VerificationCode />
                : <div className="ForgotPasswordContainer">
                    <div className="ForgotPasswordFormContainer">
                    <div className="ForgotPasswordContent">
                        <Typography component={'span'} variant={'body2'} className="ForgotPasswordFormTitle">{language.ForgotPassword.forgotPassword_header_title}</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="ForgotPasswordFormContent">
                                <TextField
                                    className={`ForgotPasswordInput ForgotPasswordEmailInput ${formik.touched.email && formik.errors.email ? 'ForgotPasswordFormInputError' : ''}`}
                                    id="email"
                                    name="email"
                                    label={<div>
                                        {language.ForgotPassword.forgotPassword_input_email}
                                        <span className="ForgotPasswordInputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    placeholder={language.ForgotPassword.forgotPassword_input_email} />
                                {showError
                                    ? <span className="ForgotPasswordShowBackError">{language.ForgotPassword.forgotPassword_show_back_error}</span>
                                    : <span className="ForgotPasswordVisibilityBackError">payload</span>}
                            </div>
                            <div className="ForgotPasswordFormFooter">
                                <Button disabled={loading} className="ForgotPasswordFormButton" type="submit">
                                    {loading ? <SpinnerButton /> : language.ForgotPassword.forgotPassword_button_forgotPassword}
                                </Button>
                                <NavLink to='/login' className="ForgotPasswordBackLogin" >
                                        <Typography component={'span'} variant={'body2'} className="ForgotPasswordBackLoginTitle">{language.ForgotPassword.forgotPassword_back_login}</Typography>
                                </NavLink>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="ForgotPasswordShowContainer">
                        <img className="LoginLogo" src={LoginLogo} alt="Logo" />
                        <img className="ForgotPasswordShowImg" src={LoginImg} alt="ForgotPasswordImg" />
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        status: state.forgotPassword.status,
        showError: state.forgotPassword.showError,
        error: state.forgotPassword.error,
        loading: state.forgotPassword.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onForgotPasswordAction: (payload) => dispatch(forgotPasswordAction(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);