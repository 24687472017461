import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';

import { fetchAreas } from "../../store/actions/areas";

import Spinner from '../../components/UI/Spinner/Spinner';

import './Areas.css';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e7f3fd',
        color: '#393737',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
    body: {
        color: '#393737',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Areas = (props) => {
    const { citiesID, loading, language, lang, styleDir, areas, last_page, onFetchAreas } = props;

    const classes = useStyles();
    const [page, setPage] = useState(1);

    useEffect(() => {
        onFetchAreas(citiesID, page);
    }, [citiesID, lang, page]);

    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="AreasList-Container">
            <div className="AreasList-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="AreasList-HeadTitle">{language.Cities.cities_areasTitle}</Typography>
            </div>

            <div className={`AreasList-ReorderContent`}>
                {loading
                    ? <Spinner />
                    : <React.Fragment>
                        <TableContainer className="AreasList-TableContainer" component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{language.Cities.cities_id}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_areaName}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_totalHotels}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_reviewedHotels}</StyledTableCell>
                                        <StyledTableCell>{language.Cities.cities_options}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {areas.map((area) => (
                                        <StyledTableRow key={area.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {area.id}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {area.name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {area['Total Hotels']}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {area['Reviewed Hotels']}
                                                <BorderLinearProgress variant="determinate" value={(area['Reviewed Hotels'] * 100) / area['Total Hotels']} />
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row">
                                                <div className="AreasList-Action">
                                                    <div className={area['Total Hotels'] > 0 ? "AreasList-OpenButton" : "AreasList-OpenButton-disabled"}>
                                                        <NavLink to={'/admin/area/' + area.id + '/hotel'}>
                                                            {language.Cities.cities_viewAll}
                                                        </NavLink>
                                                    </div>

                                                    <div className={area['Total Hotels'] === area['Reviewed Hotels'] ? "AreasList-OpenButton-disabled" : "AreasList-OpenButton"}>
                                                        <NavLink to={'/admin/area/' + area.id + '/hotel-unverify'}>
                                                            {language.Cities.cities_startReview}
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="HotelsList-PaginationContainer">
                            {areas.length > 0
                                ? <Pagination className="HotelsList-PaginationContent" count={last_page} page={page} onChange={pageHandleChange} />
                                : <Typography component={'span'} variant={'body2'} className={"HotelsList-NoData"}>{language.all.all_no_results}</Typography>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        areas: state.areas.areas,
        last_page: state.areas.last_page,
        loading: state.areas.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchAreas: (payload, page) => dispatch(fetchAreas(payload, page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Areas);