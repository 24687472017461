import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    hotels: [],
    hotelsMap: [],
    hotel: null,
    last_page: null,
    message: null, 
    status: null,  
    error: [],
    loading: false
};

const fetchHotelsStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchHotelsSuccess = (state, action) => {
    return updateObject(state, {
        hotels: action.hotels,
        last_page: action.last_page,
        error: [],
        loading: false
    });
};

const fetchHotelsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const fetchHotelsMapStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchHotelsMapSuccess = (state, action) => {
    return updateObject(state, {
        hotelsMap: action.hotelsMap,
        error: [],
        loading: false
    });
};

const fetchHotelsMapFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const addHotelStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const addHotelSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const addHotelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const resetHotels = (state, action) => {
    return updateObject(state, {
        message: null, 
        status: null,  
        error: [],
    });
};

const showHotelStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const showHotelSuccess = (state, action) => {
    return updateObject(state, {
        hotel: action.hotel,
        error: [],
        loading: false
    });
};

const showHotelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const deleteHotelStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const deleteHotelSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const deleteHotelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const changeStatusHotelStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const changeStatusHotelSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const changeStatusHotelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const changeVerifyHotelStart = (state, action) => {
    return updateObject(state, { error: null, loading: true ,message: null, status: null });
};

const changeVerifyHotelSuccess = (state, action) => {
    return updateObject(state, {
        status: action.status,
        message: action.message,
        error: [],
        loading: false
    });
};

const changeVerifyHotelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        message: action.message,
        status: action.status,
        loading: false
    });
};

const fetchHotelsByAreaStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchHotelsByAreaSuccess = (state, action) => {
    return updateObject(state, {
        hotel: action.hotel,
        error: [],
        loading: false
    });
};

const fetchHotelsByAreaFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_HOTELS_START: return fetchHotelsStart(state, action);
        case actionTypes.FETCH_HOTELS_SUCCESS: return fetchHotelsSuccess(state, action);
        case actionTypes.FETCH_HOTELS_FAIL: return fetchHotelsFail(state, action);
        case actionTypes.FETCH_HOTELS_MAP_START: return fetchHotelsMapStart(state, action);
        case actionTypes.FETCH_HOTELS_MAP_SUCCESS: return fetchHotelsMapSuccess(state, action);
        case actionTypes.FETCH_HOTELS_MAP_FAIL: return fetchHotelsMapFail(state, action);
        case actionTypes.ADD_HOTEL_START: return addHotelStart(state, action);
        case actionTypes.ADD_HOTEL_SUCCESS: return addHotelSuccess(state, action);
        case actionTypes.ADD_HOTEL_FAIL: return addHotelFail(state, action);
        case actionTypes.RESET_HOTELS: return resetHotels(state, action);
        case actionTypes.SHOW_HOTEL_START: return showHotelStart(state, action);
        case actionTypes.SHOW_HOTEL_SUCCESS: return showHotelSuccess(state, action);
        case actionTypes.SHOW_HOTEL_FAIL: return showHotelFail(state, action);
        case actionTypes.DELETE_HOTEL_START: return deleteHotelStart(state, action);
        case actionTypes.DELETE_HOTEL_SUCCESS: return deleteHotelSuccess(state, action);
        case actionTypes.DELETE_HOTEL_FAIL: return deleteHotelFail(state, action);
        case actionTypes.CHANGE_STATUS_HOTEL_START: return changeStatusHotelStart(state, action);
        case actionTypes.CHANGE_STATUS_HOTEL_SUCCESS: return changeStatusHotelSuccess(state, action);
        case actionTypes.CHANGE_STATUS_HOTEL_FAIL: return changeStatusHotelFail(state, action);
        case actionTypes.CHANGE_VERIFY_HOTEL_START: return changeVerifyHotelStart(state, action);
        case actionTypes.CHANGE_VERIFY_HOTEL_SUCCESS: return changeVerifyHotelSuccess(state, action);
        case actionTypes.CHANGE_VERIFY_HOTEL_FAIL: return changeVerifyHotelFail(state, action);
        case actionTypes.FETCH_HOTELS_BY_AREA_START: return fetchHotelsByAreaStart(state, action);
        case actionTypes.FETCH_HOTELS_BY_AREA_SUCCESS: return fetchHotelsByAreaSuccess(state, action);
        case actionTypes.FETCH_HOTELS_BY_AREA_FAIL: return fetchHotelsByAreaFail(state, action);
        default: return state;
    }
};

export default reducer;