import * as actionTypes from "../actions/actionTypes";

const initial = {
     NoIntMsg: false
};

const reducer = (state = initial, action) => {
     switch (action.type) {
          case actionTypes.NO_INTERNET:
               return { ...state, NoIntMsg: true };
          case actionTypes.RESET_NO_INTERNET:
               return { ...state, NoIntMsg: false };
          default:
               return state;
     }
 };
 
 export default reducer;