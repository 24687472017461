import axios from "../../service/axios";
import * as actionTypes from './actionTypes';
import { cleanEmpty } from "../../shared/utility";

import { logout } from './auth';

export const fetchGroupsStart = () => {
    return {
        type: actionTypes.FETCH_GROUPS_START
    };
};

export const fetchGroupsSuccess = (groups, last_page) => {
    return {
        type: actionTypes.FETCH_GROUPS_SUCCESS,
        groups: groups,
        last_page: last_page
    };
};

export const fetchGroupsFail = (error) => {
    return {
        type: actionTypes.FETCH_GROUPS_FAIL,
        error: error
    };
};

export const fetchGroups = (payload, filter) => {
    return (dispatch, getState) => {
        dispatch(fetchGroupsStart());
        const url = "/groups?paginated=" + payload;
        axios.get(url, {
            params: cleanEmpty(filter),
            headers: {
                "Accept-Language": getState().language.lang.language
            }
        })
            .then(response => {
                console.log(response);
                dispatch(fetchGroupsSuccess(response.data.data, response.data.meta.last_page));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchGroupsFail(err.response.data.error));
                }
            });
    };
};

export const fetchAllGroupsStart = () => {
    return {
        type: actionTypes.FETCH_ALL_GROUPS_START
    };
};

export const fetchAllGroupsSuccess = (allGroups) => {
    return {
        type: actionTypes.FETCH_ALL_GROUPS_SUCCESS,
        allGroups: allGroups
    };
};

export const fetchAllGroupsFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_GROUPS_FAIL,
        error: error
    };
};

export const fetchAllGroups = () => {
    return (dispatch, getState) => {
        dispatch(fetchAllGroupsStart());
        const url = "/groups";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
            }
        })
            .then(response => {
                console.log(response);
                dispatch(fetchAllGroupsSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchAllGroupsFail(err.response.data.error));
                }
            });
    };
};

export const fetchPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_START
    };
};

export const fetchPermissionsSuccess = (permissions) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_SUCCESS,
        permissions: permissions
    };
};

export const fetchPermissionsFail = (error) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchPermissions = () => {
    return (dispatch, getState) => {
        dispatch(fetchPermissionsStart());
        const url = "/groups/permissions";
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
            }
        })
            .then(response => {
                console.log(response);
                dispatch(fetchPermissionsSuccess(response.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchPermissionsFail(err.response.data.error));
                }
            });
    };
};

export const AddGroupStart = () => {
    return {
        type: actionTypes.ADD_GROUP_START
    };
};

export const AddGroupSuccess = (status) => {
    return {
        type: actionTypes.ADD_GROUP_SUCCESS,
        status: status
    };
};

export const AddGroupFail = (error) => {
    return {
        type: actionTypes.ADD_GROUP_FAIL,
        error: error
    };
};

export const AddGroup = (payload) => {
    return (dispatch, getState) => {
        dispatch(AddGroupStart());
        const url = "/groups";
        axios.post(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(AddGroupSuccess(response.status));
                dispatch(fetchGroups(1, {}));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(AddGroupFail(err.response.data.error));
                }
            });
    };
};

export const resetGroupsInit = () => {
    return {
        type: actionTypes.RESET_GROUPS_INIT
    };
};

export const resetGroups = () => {
    return dispatch => {
        dispatch(resetGroupsInit());
    };
};

export const fetchGroupStart = () => {
    return {
        type: actionTypes.FETCH_GROUP_START
    };
};

export const fetchGroupSuccess = (group) => {
    return {
        type: actionTypes.FETCH_GROUP_SUCCESS,
        group: group
    };
};

export const fetchGroupFail = (error) => {
    return {
        type: actionTypes.FETCH_GROUP_FAIL,
        error: error
    };
};

export const fetchGroup = (payload) => {
    return (dispatch, getState) => {
        dispatch(fetchGroupStart());
        const url = "/groups/" + payload;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
            }
        })
            .then(response => {
                console.log(response);
                dispatch(fetchGroupSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchGroupFail(err.response.data.error));
                }
            });
    };
};


export const editGroupStart = () => {
    return {
        type: actionTypes.EDIT_GROUP_START
    };
};

export const editGroupSuccess = (status) => {
    return {
        type: actionTypes.EDIT_GROUP_SUCCESS,
        status: status
    };
};

export const editGroupFail = (error) => {
    return {
        type: actionTypes.EDIT_GROUP_FAIL,
        error: error
    };
};

export const editGroup = (payload, groupID) => {
    return (dispatch, getState) => {
        dispatch(editGroupStart());
        const url = "/groups/" + groupID;
        axios.put(url, payload, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(editGroupSuccess(response.status));
                dispatch(fetchGroups(1, {}));
            })
            .catch(err => {
                console.log(err.response);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(editGroupFail(err.response.data.error));
                }
            });
    };
};
