import React from 'react';
import { connect } from 'react-redux';

import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { create } from 'jss';
import rtl from 'jss-rtl';

import Aux from '../Aux/Aux';
import classes from './Layout.css';

const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
});

const Layout = props => {
    const { language, styleDir } = props;
    return (
        <Aux>
            <ThemeProvider
                theme={createTheme({
                    direction: styleDir
                })}
            >
                <StylesProvider jss={jss}>
                    <div className={classes.Content} dir={styleDir} lang={language}>
                        {props.children}
                    </div>
                </StylesProvider>
            </ThemeProvider>
        </Aux>
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        language: state.language.lang.language,
        styleDir: state.language.styleDir,
    };
};

export default connect(mapStateToProps)(Layout);