import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { deleteHotel } from "../../../store/actions/hotels";

import './DeleteHotel.css';

const DeleteHotel = (props) => {
    const { language, Closed, loading, HotelTitle, HotelID, styleDir, lang, statusAPI, onDeleteHotel } = props;

    useEffect(() => {
        if(statusAPI === 201){
            Closed();
        }
    }, [statusAPI]);

    const DeleteHotelHandleClick = () => {
        onDeleteHotel(HotelID);
    }

    return (
        <React.Fragment>
            <div className="DeleteHotelContainer">
                <div className="DeleteHotel-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="DeleteHotel-HeadTitle">{language.Hotels.hotels_delete}<span className="DeleteHotel-SubHeadTitle">{' '+HotelTitle}</span></Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="DeleteHotelLine"></div>
                <div className="DeleteHotelFormContainer">
                    <div className="DeleteHotelFormContent">
                        <Typography component={'span'} variant={'body2'} className="DeleteHotel-ContentTitle">{language.Hotels.hotels_sure_delete}<span className="DeleteHotel-SubContentTitle">{HotelTitle}</span>{' ?'}</Typography>
                    </div>
                    <div className="DeleteHotelFormFooter">
                        <Button  className="DeleteHotelCancelButton" onClick={Closed}>
                            {language.all.all_cancel_btn_title}
                        </Button>

                        <Button disabled={loading} className="DeleteHotelDeleteHotelButton" onClick={DeleteHotelHandleClick}>
                            {loading ? <SpinnerButton /> : language.Hotels.hotels_delete}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.hotels.loading,
        statusAPI: state.hotels.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteHotel: (ID) => dispatch(deleteHotel(ID))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteHotel));