import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { changeStatusUser } from "../../../store/actions/users";

import './ChangeStatusUser.css';

const ChangeStatusUser = (props) => {
    const { language, Closed, loading, UserName, UserID, styleDir, lang, statusAPI, onChangeStatusUser, Model, Status } = props;

    useEffect(() => {
        if(statusAPI === 200){
            Closed();
        }
    }, [statusAPI]);

    const ChangeStatusUserHandleClick = () => {
        let statusCode = Status === 1 ? 'activate' : 'deactivate';
        onChangeStatusUser(statusCode, UserID);
    }

    return (
        <React.Fragment>
            <div className="ChangeStatusUserContainer">
                <div className="ChangeStatusUser-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="ChangeStatusUser-HeadTitle">{Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate}<span className="ChangeStatusUser-SubHeadTitle">{' '+UserName}</span></Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="ChangeStatusUserLine"></div>
                <div className="ChangeStatusUserFormContainer">
                    <div className="ChangeStatusUserFormContent">
                        <Typography component={'span'} variant={'body2'} className="ChangeStatusUser-ContentTitle">{language.Hotels.hotels_sure}{' '}{Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate}{' '}<span className="ChangeStatusUser-SubContentTitle">{UserName}</span>{' ?'}</Typography>
                    </div>
                    <div className="ChangeStatusUserFormFooter">
                        <Button  className="ChangeStatusUserCancelButton" onClick={Closed}>
                        {language.all.all_cancel_btn_title}
                        </Button>

                        <Button disabled={loading} className={Model === 'active' ? 'ChangeStatusUserButton-active' : 'ChangeStatusUserButton-Deactive'} onClick={ChangeStatusUserHandleClick}>
                            {loading ? <SpinnerButton /> : (Model === 'active' ? language.Hotels.hotels_active : language.Hotels.hotels_deactivate)}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.hotels.loading,
        statusAPI: state.hotels.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeStatusUser: (payload, UserID) => dispatch(changeStatusUser(payload, UserID))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeStatusUser));