import axios from "../../service/axios";
import * as actionTypes from './actionTypes';

export const verificationCodeStart = () => {
    return {
        type: actionTypes.VERIFICATION_CODE_START
    };
};

export const verificationCodeSuccess = (payload, showError, status) => {
    return {
        type: actionTypes.VERIFICATION_CODE_SUCCESS,
        payload: payload,
        status: status,
        showError: !showError
    };
};

export const verificationCodeFail = (error) => {
    return {
        type: actionTypes.VERIFICATION_CODE_FAIL,
        payload: error.data,
        status: error.status,
        showError: !error.success,
        error: error.message
    };
};

export const verificationCodeAction = (payload) => {
    return dispatch => {
        dispatch(verificationCodeStart());

        const url = "/auth/forgetPassword/email/confirm";
        axios.post(url, payload)
            .then(response => {
                console.log(response);
                dispatch(verificationCodeSuccess(response.data.data, response.data.status, response.data.status));
            })
            .catch(err => {
                if(err.response === undefined){
                    let failPayload = {
                        data: '',
                        status: false,
                        showError: true,
                        error: 'failed'
                    }
                    dispatch(verificationCodeFail(failPayload));
                }else{
                    console.log(err.response.data);
                    dispatch(verificationCodeFail(err.response.data));
                }
            });
    };
};