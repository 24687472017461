import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import languageReducer from './store/reducers/language';
import authReducer from './store/reducers/auth';
import forgotPasswordReducer from './store/reducers/forgotPassword';
import verificationCodeReducer from './store/reducers/verificationCode';
import resetPasswordReducer from './store/reducers/resetPassword';
import noInternetReducer from './store/reducers/NoInternet';
import usersReducer from './store/reducers/users';
import groupsReducer from './store/reducers/groups';
import citiesReducer from './store/reducers/cities';
import countryReducer from './store/reducers/country';
import areasReducer from './store/reducers/areas';
import hotelsReducer from './store/reducers/hotels';
import facilityTypeReducer from './store/reducers/facilityType';
import lookupsReducer from './store/reducers/lookups';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
//const composeEnhancers = null || compose;

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    language: languageReducer,
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    verificationCode: verificationCodeReducer,
    resetPassword: resetPasswordReducer,
    noInternet: noInternetReducer,
    users: usersReducer,
    groups: groupsReducer,
    cities: citiesReducer,
    country: countryReducer,
    areas: areasReducer,
    hotels: hotelsReducer,
    facilityType: facilityTypeReducer,
    lookups: lookupsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
));

let persistor = persistStore(store);

const app = (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
reportWebVitals();