import React from 'react';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';

import Toolbar from '../../components/Toolbar/Toolbar';
import Header from '../../components/Header/Header';
import GroupsList from '../../components/Groups/Groups';

const Groups = props => {
  const { language } = props;

  let homeRender = (
    <Aux>
     <GroupsList />
    </Aux>
  )

  return (
      <Aux>
          <Toolbar />
          <Header />
          {homeRender}
      </Aux>
  );
}

const mapStateToProps = state => {
  return {
    language: state.language.lang
  };
};

export default connect(mapStateToProps)(Groups);