import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import FormControl from '@material-ui/core/FormControl';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';

import { fetchUsers, reSetUsers, changeStatusUser, deleteUser } from "../../store/actions/users";

import NewUser from './NewUser/NewUser';
import EditUser from './EditUser/EditUser';
import ChangePassword from './ChangePassword/ChangePassword';

import DeleteUser from './DeleteUser/DeleteUser';
import ChangeStatusUser from './ChangeStatusUser/ChangeStatusUser';

import Snackbars from '../../components/UI/Snackbars/Snackbars';
import Spinner from '../../components/UI/Spinner/Spinner';

import './Users.css';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e7f3fd',
        color: '#393737',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
    body: {
        color: '#393737',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Users = (props) => {
    const { loading, language, lang, styleDir, statusAPI, adminEmail, error=[], message, last_page, users, onFetchUsers, onReSetUsers, onChangeStatusUser, onDeleteUser } = props;

    const classes = useStyles();

    const [filter, setFilter] = useState({});
    const [page, setPage] = useState(1);

    const [searchState, setSearchState] = useState();
    const [status, setStatus] = useState('');

    const [idSelected, setIdSelected] = useState();
    const [userSelected, setUserSelected] = useState();
    const [userNameSelected, setUserNameSelected] = useState();
    const [statusSelected, setStatusSelected] = useState();
    const [modelSelected, setModelSelected] = useState();

    const [anchorElDeleteUser, setAnchorElDeleteUser] = useState(null);
    const [anchorElChangeStatusUser, setAnchorElChangeStatusUser] = useState(null);

    const [anchorElNewUser, setAnchorElNewUser] = useState(null);
    const [anchorElEditUser, setAnchorElEditUser] = useState(null);
    const [anchorElChangePassword, setAnchorElChangePassword] = useState(null);

    const searchHandler = (prop) => (event) => {
        console.log(prop, event.target.value);
        setFilter({ ...filter, q: event.target.value });
        setSearchState(event.target.value)
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value.target.value);
        setFilter({ ...filter, status: event.target.value.target.value });
    };

    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        onReSetUsers();
    }, []);

    useEffect(() => {
        if(statusAPI === 201){
            newUserhandleClose();
            editUserhandleClose();
        }
        if(statusAPI === 200){
            changePasswordhandleClose();
            deleteUserhandleClose();
            ChangeStatusUserhandleClose();
        }
    }, [statusAPI])

    useEffect(() => {
        onFetchUsers(page, filter);
    }, [page, filter, lang]);

    const resetHandleChange = (event) => {
        setSearchState('');
        setStatus('');
        onFetchUsers(page, {});
    }

    const newUserHandleClick = (event) => {
        setAnchorElNewUser(event.currentTarget);
    };

    const newUserhandleClose = () => {
        setAnchorElNewUser(null);
    };

    const editUserHandleClick = (event, User) => {
        setAnchorElEditUser(event.currentTarget);
        console.log(User);
        setUserSelected(User);
    };

    const editUserhandleClose = () => {
        setAnchorElEditUser(null);
    };

    const changePasswordHandleClick = (event, userID) => {
        setIdSelected(userID);
        setAnchorElChangePassword(event.currentTarget);
    };

    const changePasswordhandleClose = () => {
        setAnchorElChangePassword(null);
    };

    const deleteUserHandleClick = (event, id, name) => {
        setAnchorElDeleteUser(event.currentTarget);
        setUserNameSelected(name);
        setIdSelected(id);
    };

    const deleteUserhandleClose = () => {
        setAnchorElDeleteUser(null);
    };

    const changeStatusUserHandleClick = (event, id, name, status, model) => {
        setAnchorElChangeStatusUser(event.currentTarget);
        setUserNameSelected(name);
        setIdSelected(id);
        setStatusSelected(status);
        setModelSelected(model);
    }

    const ChangeStatusUserhandleClose = () => {
        setAnchorElChangeStatusUser(null);
    };

    const openNewUser = Boolean(anchorElNewUser);
    const idNewUser = openNewUser ? 'mouse-over-popover-NewUser' : undefined;

    const openEditUser = Boolean(anchorElEditUser);
    const idEditUser = openEditUser ? 'mouse-over-popover-EditUser' : undefined;

    const openChangePassword = Boolean(anchorElChangePassword);
    const idChangePassword = openChangePassword ? 'mouse-over-popover-ChangePassword' : undefined;

    const openDeleteUser = Boolean(anchorElDeleteUser);
    const idDeleteUser = openDeleteUser ? 'mouse-over-popover-DeleteUser' : undefined;

    const openChangeStatusUser = Boolean(anchorElChangeStatusUser);
    const idChangeStatusUser = openChangeStatusUser ? 'mouse-over-popover-ChangeStatusUser' : undefined;

    return (
        <div className="UsersList-Container">
            <div className="UsersList-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="UsersList-HeadTitle">{language.UsersManagement.usersManagement_users}</Typography>
            </div>

            <div className="UsersList-HeadNavContainer">
                <div className="UsersList-UserNav">
                    <NavLink to='/admin/users' className="UsersList-UsersNavLink" >
                        <Typography component={'span'} variant={'body2'} className="UsersList-UserNavTitle">
                            <GroupIcon />
                            {language.UsersManagement.usersManagement_usersTitle}
                        </Typography>
                    </NavLink>

                </div>
                <div className="UsersList-RolesNav">
                    <NavLink to='/admin/roles' className="UsersList-RolesNavLink" >
                        <Typography component={'span'} variant={'body2'} className="UsersList-RolesNavTitle">
                            <GroupWorkIcon />
                            {language.UsersManagement.usersManagement_roles}
                        </Typography>
                    </NavLink>
                </div>
            </div>
            <div className={`UsersList-ReorderContent`}>
                <div className={`UsersList-HeadContent`}>
                    <div className={`UsersList-SearchContainer`}>
                        <TextField
                            className={`UsersList-Input UsersList-SearchInput`}
                            id="search"
                            name="search"
                            label={<div>
                                {language.UsersManagement.usersManagement_search}
                            </div>}
                            variant="outlined"
                            value={searchState}
                            onChange={searchHandler()}
                            placeholder={language.UsersManagement.usersManagement_search} />

                        <FormControl
                            className={`UsersList-Input UsersList-StatusInput`}
                            variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                <div>
                                    {language.UsersManagement.usersManagement_status}
                                </div>
                            </InputLabel>
                            <Select
                                dir={styleDir}
                                lang={lang}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={status}
                                name="status"
                                onChange={selectedOption => {
                                    let event = { target: { name: 'status', value: selectedOption } }
                                    handleChangeStatus(event);
                                }}
                                label={<div>
                                    {language.UsersManagement.usersManagement_status}
                                </div>}
                            >
                                <MenuItem className="UsersList-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                    {language.UsersManagement.usersManagement_status}
                                </MenuItem>
                                <MenuItem className="UsersList-MenuItem" dir={styleDir} lang={lang} value={'active'}>{language.UsersManagement.usersManagement_active}</MenuItem>
                                <MenuItem className="UsersList-MenuItem" dir={styleDir} lang={lang} value={'pending'}>{language.UsersManagement.usersManagement_pending}</MenuItem>
                                <MenuItem className="UsersList-MenuItem" dir={styleDir} lang={lang} value={'inactive'}>{language.UsersManagement.usersManagement_deactivate}</MenuItem>
                            </Select>
                        </FormControl>

                        <div onClick={resetHandleChange} className={`UsersList-ResetContainer`}>
                            <RefreshIcon />
                            <Typography component={'span'} variant={'body2'} className="UsersList-CountHeadTitle">{language.UsersManagement.usersManagement_reset}</Typography>
                        </div>

                    </div>
                    <div aria-describedby={idNewUser} className="UsersList-NewUserButton" onClick={newUserHandleClick}>
                        {language.UsersManagement.usersManagement_new_user}
                    </div>
                </div>
                {loading
                    ? <Spinner />
                    : <React.Fragment>
                        <TableContainer className="UsersList-TableContainer" component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_name}</StyledTableCell>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_emailAddress}</StyledTableCell>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_mobileNumber}</StyledTableCell>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_admin}</StyledTableCell>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_status}</StyledTableCell>
                                        <StyledTableCell>{language.UsersManagement.usersManagement_actions}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <StyledTableRow key={user.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {user.name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {user.email}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {user.phone_number}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {user.is_admin === 1
                                                    ? <span className={`UsersList-isAdmin`}>{language.UsersManagement.usersManagement_yes}</span>
                                                    : <span className={`UsersList-notAdmin`}>{language.UsersManagement.usersManagement_no}</span>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {user.status === 'Active' || user.status === 'مفعل'
                                                    ? <span className={`UsersList-Active`}>{user.status}</span>
                                                    : <span className={`UsersList-InActive`}>{user.status}</span>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <div className="UsersList-Action">
                                                    <div aria-describedby={idEditUser} onClick={(event, User) => editUserHandleClick(event, user)} className="UsersList-EditButton" >
                                                        <EditIcon /> {language.UsersManagement.usersManagement_edit}
                                                    </div>
                                                    <div aria-describedby={idChangePassword} onClick={(event, id) => changePasswordHandleClick(event, user.id)} className="UsersList-ChangePasswordButton">
                                                        <VpnKeyOutlinedIcon /> {language.UsersManagement.usersManagement_changePassword}
                                                    </div>

                                                    {adminEmail !== user.email ? (<div aria-describedby={idDeleteUser}  className="UsersList-DeleteButton" onClick={(event, ID, Name) => deleteUserHandleClick(event, user.id, user.name)}>
                                                        <DeleteOutlineIcon /> {language.UsersManagement.usersManagement_delete}
                                                    </div>) : null}
                                                    
                                                    {adminEmail !== user.email ? (user.status === 'pending' || user.status === 'InActive' || user.status === 'قيد الانتظار' || user.status === ' غير مفعل '
                                                        ? <div aria-describedby={idChangeStatusUser} className="UsersList-ActiveButton" onClick={(event, ID, Title, Status, Model) => changeStatusUserHandleClick(event, user.id, user.name, 1, 'active')} >
                                                            <CheckCircleIcon /> {language.UsersManagement.usersManagement_active}
                                                        </div>
                                                        : <div aria-describedby={idChangeStatusUser} className="UsersList-DeactivateButton" onClick={(event, ID, Title, Status, Model) => changeStatusUserHandleClick(event, user.id, user.name, 0, 'Deactive')} >
                                                            <BlockIcon /> {language.UsersManagement.usersManagement_deactivate}
                                                        </div>) : null}
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="UsersList-PaginationContainer">
                            {users.length > 0
                                ? <Pagination className="UsersList-PaginationContent" count={last_page} page={page} onChange={pageHandleChange} />
                                : <Typography component={'span'} variant={'body2'} className={"UsersList-NoData"}>{language.all.all_no_results}</Typography>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>

            <Popover
                className="UsersList-NewUserContainer"
                id={idNewUser}
                open={openNewUser}
                anchorEl={anchorElNewUser}
                dir={language.dir}
                lang={language.language}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UseresList-PaperNewUser'}>
                    <NewUser Closed={newUserhandleClose} />
                </div>
            </Popover>

            <Popover
                className="UsersList-EditUserContainer"
                id={idEditUser}
                open={openEditUser}
                anchorEl={anchorElEditUser}
                dir={language.dir}
                lang={language.language}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UseresList-PaperEditUser'}>
                    <EditUser Closed={editUserhandleClose} UserSelected={userSelected} />
                </div>
            </Popover>

            <Popover
                className="UsersList-ChangePasswordContainer"
                id={idChangePassword}
                open={openChangePassword}
                anchorEl={anchorElChangePassword}
                dir={language.dir}
                lang={language.language}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UseresList-PaperChangePassword'}>
                    <ChangePassword Closed={changePasswordhandleClose} IDSelected={idSelected} />
                </div>
            </Popover>

            <Popover
                className="UsersList-DeleteUserContainer"
                id={idDeleteUser}
                open={openDeleteUser}
                anchorEl={anchorElDeleteUser}
                dir={language.dir}
                lang={language.language}
                onClose={deleteUserhandleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UsersList-PaperDeleteUser'}>
                    <DeleteUser Closed={deleteUserhandleClose} UserName={userNameSelected} UserID={idSelected} />
                </div>
            </Popover>

            <Popover
                className="UsersList-ChangeStatusUserContainer"
                id={idChangeStatusUser}
                open={openChangeStatusUser}
                anchorEl={anchorElChangeStatusUser}
                dir={language.dir}
                lang={language.language}
                onClose={ChangeStatusUserhandleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UsersList-PaperChangeStatusUser'}>
                    <ChangeStatusUser Closed={ChangeStatusUserhandleClose} UserName={userNameSelected} UserID={idSelected} Model={modelSelected} Status={statusSelected} />
                </div>
            </Popover>

            {statusAPI === 201 || statusAPI === 200
                ? <Snackbars openSnack={true} message={message} errors={null} statusCode={statusAPI} status={'success'} hideDuration={6000} />
                : null}

            {statusAPI === 422 || statusAPI === 417
                ? <Snackbars openSnack={true} message={message} errors={error} statusCode={statusAPI} status={'error'} hideDuration={6000} />
                : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        adminEmail: state.auth.adminEmail,
        users: state.users.users,
        loading: state.users.loading,
        last_page: state.users.last_page,
        message: state.users.message,
        statusAPI: state.users.status,
        error: state.users.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUsers: (payload, filter) => dispatch(fetchUsers(payload, filter)),
        onChangeStatusUser: (payload, UserID) => dispatch(changeStatusUser(payload, UserID)),
        onDeleteUser: (payload, UserID) => dispatch(deleteUser(payload, UserID)),
        onReSetUsers: () => dispatch(reSetUsers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);