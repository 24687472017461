import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    facilityType: [],
    message: null, 
    error: [],
    loading: false
};

const fetchFacilityTypeStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchFacilityTypeSuccess = (state, action) => {
    return updateObject(state, {
        facilityType: action.facilityType,
        error: [],
        loading: false
    });
};

const fetchFacilityTypeFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FACILITY_TYPE_START: return fetchFacilityTypeStart(state, action);
        case actionTypes.FETCH_FACILITY_TYPE_SUCCESS: return fetchFacilityTypeSuccess(state, action);
        case actionTypes.FETCH_FACILITY_TYPE_FAIL: return fetchFacilityTypeFail(state, action);
        default: return state;
    }
};

export default reducer;