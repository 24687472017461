const arabic = {
    dir: "rtl",
    language: "ar",

    "all": {
        "all_ok_btn_title": "موافق",
        "all_cancel_btn_title": "إلغاء",
        "all_done_btn_title": "تم",
        "all_back_btn_title": "رجوع",
        "all_yes_btn_title": "نعم",
        "all_no_btn_title": "لا",
        "all_ar": "العربية",
        "all_en": "English",
        "all_or_lbl_title": "أو",
        "all_chage_language": "تغيير اللغة",
        "all_alert": "تنبيه",
        "all_connected": "متصل",
        "all_ok": "موافق",
        "all_cancel": "إلغاء",
        "all_done": "تم",
        "all_egp_currency_title": "جنية",
        "all_usd_currency_title": "دولار",
        "all_eur_currency_title": "يورو",
        "all_sar_currency_title": "ريال",
        "all_aed_currency_title": "درهم",
        "all_no_internet_title": "لا يوجد إنترنت",
        "all_no_results": "لم يتم العثور على نتائج"
    },
    "Login": {
        "login_header_title": "مرحباً ,من فضلك أدخل البيانات التالية ",
        "login_input_email": "البريد الالكتروني",
        "login_input_password": "كلمة المرور",
        "login_button_login": "تسجيل الدخول",
        "login_remember_me": "تذكرني لاحقاً",
        "login_forgot_password": "هل نسيت كلمة السر ؟",
        "login_email_required": "الرجاء إدخال البريد الإلكتروني الخاص بك ",
        "login_email_valid": " البريد الإلكتروني غير صحيح",
        "login_password_required": "لا يمكن أن تكون كلمة المرور فارغة",
        "login_password_min_valid": "يجب ألا تقل كلمة المرور عن 6 أحرف",
        "login_password_max_valid": "يجب أن تكون كلمة المرور بين 6 و 20 حرفًا",
        "login_show_back_error": "اسم المستخدم أو كلمة المرور خاطئة"
    },
    "ForgotPassword": {
        "forgotPassword_header_title": "مرحباً, من فضلك أدخل البيانات التالية لاستعادة كلمة المرور ",
        "forgotPassword_input_email": "البريد الالكتروني ",
        "forgotPassword_button_forgotPassword": "استعادة كلمة المرور",
        "forgotPassword_email_required": "الرجاء إدخال البريد الإلكتروني الخاص بك",
        "forgotPassword_email_valid": " البريد الإلكتروني غير صحيح",
        "forgotPassword_show_back_error": "خطأ أثناء إرسال البريد الإلكتروني",
        "forgotPassword_back_login": "الرجوع لتسجيل الدخول"

    },
    "VerificationCode": {
        "verificationCode_header_title": "مرحباً, من فضلك أدخل البيانات التالية لاستعادة كلمة المرور ",
        "verificationCode_input_code": "ادخل الرمز المرسل",
        "verificationCode_button_verificationCode": "تأكيد الرمز",
        "verificationCode_code_required": "الرجاء إدخال الرمز الخاص بك",
        "verificationCode_code_invalid": "كود التحقق غير صحيح ، برجاء المحاولة مرة أخرى",
        "verificationCode_show_back_error": "كود التحقق غير صحيح ، برجاء المحاولة مرة أخرى"
    },
    "ResetPassword": {
        "resetPassword_header_title": "مرحباً, من فضلك أدخل البيانات التالية ",
        "resetPassword_input_newPassword": "كلمة المرور الجديدة",
        "resetPassword_input_confirmPassword": "تأكيد كلمة المرور الجديدة",
        "resetPassword_button_resetPassword": "تغيير كلمة المرور",
        "resetPassword_show_back_error": "لم نتمكن من تنفيذ طلبك الآن. يرجى المحاولة لاحقاً",
        "new_password_field_error_msg_invalid_password": "يجب ألا تقل كلمة المرور الجديدة عن 6 أحرف أو أرقام",
        "new_password_field_error_msg_empty": "لا يمكن أن تكون كلمة المرور الجديدة خالية",
        "password_field_strength_default_description": "يجب أن تكون كلمة المرور من 6 إلى 20 حرفا",
        "password_field_error_msg_password_doesnt_match": "يجب أن تتطابق كلمة المرور مع كلمة المرور الجديدة"
    },
    "Toolbar": {
        "toolbar_language": "English",
        "toolbar_change_password": "تغير كلمة المرور",
        "toolbar_logout": "تسجيل الخروج"
    },
    "Header": {
        "header_dashboard": "الرئيسية",
        "header_all_facilities": "الفنادق",
        "header_map_exploler": "مستكشف الخرائط",
        "header_list_cities": "قائمة المدن",
        "header_manage_users": "ادارة المستخدمين"
    },
    "Hotels": {
        "hotels_title": "الفنادق",
        "hotels_search": "بحث",
        "hotels_status": "الحالة",
        "hotels_city": "المدينة",
        "hotels_areas": "المناطق",
        "hotels_area": "المنطقة",
        "hotels_star": "نجمة",
        "hotels_reset_filter": "إعادة تعيين",
        "hotels_new_facility": "إضافة منشأة جديدة",
        "hotels_photo": "صورة",
        "hotels_id": "رقم",
        "hotels_facility_name": "اسم المنشأة",
        "hotels_distinace_haram": "المسافه من الحرم",
        "hotels_review": "مراجعة",
        "hotels_actions": "الإجراءات",
        "hotels_view": "عرض",
        "hotels_edit": "تعديل",
        "hotels_direction": "الاتجاه",
        "hotels_delete": "حذف",
        "hotels_active": "تفعيل",
        "hotels_deactivate": "ايقاف",
        "hotels_details": "التفاصيل",
        "hotels_facility_type": "نوع المنشأة",
        "hotels_choose_facility_type": "اختار نوع المنشأة",
        "hotels_country": "البلد",
        "hotels_choose_country": "اختار بلدًا",
        "hotels_choose_city": "اختار مدينة",
        "hotels_choose_area": "اختار منطقة",
        "hotels_building_number": "رقم المبنى",
        "hotels_street": "شارع",
        "hotels_district": "المنطقة",
        "hotels_facilityPobox": "صندوق البريد المنشأة",
        "hotels_facilityPhone": "هاتف المنشأة",
        "hotels_facilityEmail": "البريد الإلكتروني للمنشأة",
        "hotels_facilityClassification_type": "نوع تصنيف المنشأة",
        "hotels_choose_facilityClassification_type": "اختار نوع تصنيف المنشأة",
        "hotels_facilityClassification_startDate": "تاريخ بدء تصنيف المنشأة",
        "hotels_facilityClassification_endDate": "تاريخ انتهاء تصنيف المنشأة",
        "hotels_english": "English",
        "hotels_arabic": "العربية",
        "hotels_placeName": "اسم المكان",
        "hotels_location": "الموقع",
        "hotels_description": "الوصف",
        "hotels_mapLocation": "موقع الخريطة",
        "hotels_hotelCoordinates": "إحداثيات الفندق",
        "hotels_coordinates_lat": "X COORDINATE (LAT)",
        "hotels_coordinates_long": "Y COORDINATE (LONG)",
        "hotels_haramCoordinates": "إحداثيات أبواب الحرم",
        "hotels_rooms": "الغرف",
        "hotels_pricingAmount": "السعر",
        "hotels_renewalCount": "عدد التجديد",
        "hotels_floorsCount": "عدد الطوابق",
        "hotels_deluxeRoomsCount": "عدد الغرف الفاخرة",
        "hotels_regularRoomsCount": "عدد الغرف العادية",
        "hotels_regularSuitesCount": "عدد الأجنحة العادي",
        "hotels_deluxeSuitesCount": "عدد الأجنحة الفاخرة",
        "hotels_parkingCount": "عدد مواقف السيارات",
        "hotels_facilities": "المرافق",
        "hotels_information": "معلومات",
        "hotels_facilityOwner": "مالك المنشأة",
        "hotels_operatorName": "اسم المشغل",
        "hotels_operatorGender": "نوع المشغل",
        "hotels_choose_operatorGender": "اختار نوع المشغل",
        "hotels_licensingInvestor": "رقم الترخيص للمستثمر",
        "hotels_facilityLicensingStartDate": "تاريخ بدء ترخيص المنشأة",
        "hotels_photos": "الصور",
        "hotels_uploadPhotos": "تحميل الصور",
        "hotels_photos_allRemove": "حذف كل الصور",
        "hotels_photos_update": "تعديل",
        "hotels_photos_remove": "حذف",
        "hotels_add": "اضافة",
        "hotels_back": "رجوع",
        "hotels_edit_facility": "تعديل",
        "hotels_distance_haramDoor": "المسافة من باب الحرم",
        "hotels_typeID": "Type ID",
        "hotels_jumpTo": "انتقل إلى",
        "hotels_previous": "سابق",
        "hotels_verify": "يؤكد",
        "hotels_unVerify": "غير مؤكد",
        "hotels_next": "التالي",
        "hotels_coordinates_haram_lat": "X COORDINATE (LAT) Haram Door",
        "hotels_coordinates_haram_long": "Y COORDINATE (LONG) Haram Door",
        "hotels_filterBy": "تصفية حسب",
        "hotels_resetFilter": "إعادة تعيين الفلتر",
        "hotels_hotel": "فندق",
        "hotels_building": "مبنى",
        "hotels_furnished_housing": "الوحدات السكنية المفروشة",
        "hotels_2stars": "نجمتان",
        "hotels_3stars": "3 نجوم",
        "hotels_4stars": "4 نجوم",
        "hotels_5stars": "5 نجوم",
        "hotels_all": "الكل",
        "hotels_from0to500": "من 0 - إلى 500",
        "hotels_from500to1000": "من 500 - إلى 1000",
        "hotels_from1000to2000": "من 1000 إلى 2000",
        "hotels_from2000to3000": "من 2000 إلى 3000",
        "hotels_from3000to4000": "من 3000 - إلى 4000",
        "hotels_from4000to5000": "من 4000 - إلى 5000",
        "hotels_from5000": "من 5000",
        "hotels_distanceFromHaram": "المسافة من الحرم",
        "hotels_starRating": "تصنيف النجوم",
        "hotels_sure_delete": "هل انت متأكد من الحذف",
        "hotels_sure": "هل انت متأكد من"
    },
    "Cities": {
        "cities_title": "قائمة المدن",
        "cities_areasTitle": "قائمة المناطق",
        "cities_id": "#",
        "cities_cityName": "اسم المدينة",
        "cities_totalHotels": "إجمالي الفنادق",
        "cities_reviewedHotels": "الفنادق التي تمت مراجعتها",
        "cities_options": "خيارات",
        "cities_open": "فتح",
        "cities_areaName": "اسم المنطقة",
        "cities_viewAll": "عرض الكل",
        "cities_startReview": "بدء المراجعة",
    },
    "UsersManagement": {
        "usersManagement_users": "إدارة المستخدمين",
        "usersManagement_groups": "إدارة المجموعات",
        "usersManagement_usersTitle": "المستخدمون",
        "usersManagement_roles": "الأدوار",
        "usersManagement_search": "بحث",
        "usersManagement_status": "الحالة",
        "usersManagement_reset": "إعادة تعيين",
        "usersManagement_new_user": "إضافة مستخدم جديد",
        "usersManagement_name": "الاسم",
        "usersManagement_emailAddress": "البريد الإلكتروني",
        "usersManagement_mobileNumber": "رقم الهاتف",
        "usersManagement_admin": "الادمن",
        "usersManagement_actions": "الاعدادات",
        "usersManagement_edit": "تعديل",
        "usersManagement_changePassword": "تغيير كلمة المرور",
        "usersManagement_delete": "حذف",
        "usersManagement_active": "تفعيل",
        "usersManagement_pending": "قيد الانتظار",
        "usersManagement_deactivate": "ايقاف",
        "usersManagement_fullName": "الاسم كامل",
        "usersManagement_phoneNumber": "رقم الهاتف",
        "usersManagement_isAdmin": "IS SUPER ADMIN",
        "usersManagement_yes": "نعم",
        "usersManagement_no": "لا",
        "usersManagement_role": "الدور",
        "usersManagement_choose_role": "اختر دورًا",
        "usersManagement_new_user_button": "مستخدم جديد",
        "usersManagement_edit_user_button": "تعديل المستخدم",
        "usersManagement_newPassword": "كلمة مرور جديدة",
        "usersManagement_confirmNewPassword": "تأكيد كلمة المرور الجديدة",
        "usersManagement_new_role": "إضافة دور جديد",
        "usersManagement_role_name": "اسم الدور",
        "usersManagement_save": "حفظ",
        "usersManagement_edit_role": "تعديل الدور",
        "usersManagement_create_new_role": "إنشاء دور جديد",
        "usersManagement_valid_fullName": "الرجاء إدخال اسم كامل صالح",
        "usersManagement_empty_fullName": "الاسم الكامل لا يمكن أن يكون فارغاً",
        "usersManagement_valid_phone": "الرجاء إدخال رقم هاتف صالح",
        "usersManagement_empty_phone": "رقم الهاتف لا يمكن أن يكون فارغاً",
        "usersManagement_valid_email": "الرجاء إدخال بريد إلكتروني صالح",
        "usersManagement_empty_email": "لا يمكن أن يكون البريد الإلكتروني فارغًا",
        "usersManagement_valid_role": "الرجاء إدخال اسم دور صالح",
        "usersManagement_empty_role": "اسم الدور لا يمكن أن يكون فارغًا",
        "usersManagement_empty_password": "كلمة المرور لا يمكن أن تكون فارغة",
        "usersManagement_valid_password": "يجب أن تكون كلمة المرور بين 6 و 20 حرفًا",
        "usersManagement_empty_confirmPassword": "تأكيد كلمة المرور لا يمكن أن يكون فارغًا",
        "usersManagement_valid_confirmPassword": "تأكيد كلمة المرور يجب أن يكون بين 6 و 20 حرفًا",
        "usersManagement_match_confirmPassword": "تأكيد كلمة المرور يجب أن يتطابق مع كلمة المرور",
    }
};

export default arabic;