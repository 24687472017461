import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';

import Spinner from '../../components/UI/Spinner/Spinner';

import GoogleMap from '../GoogleMap/GoogleMap';

import { fetchHotelsMap } from "../../store/actions/hotels";
import { fetchCitiesWithCountry } from "../../store/actions/cities";
import { fetchAreas } from "../../store/actions/areas";

import './HotelsMap.css';

const HotelsMap = (props) => {
    const { language, lang, styleDir, loading, hotels, cities, areas = [], onFetchHotelsMap, onFetchCities, onFetchAreas } = props;

    const firstRender = useRef(false);
    const firstRenderMap = useRef(false);

    const [searchState, setSearchState] = useState();
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');

    const [mapDefaultCenter, setMapdefaultCenter] = useState({ lat: 21.42184037, lng: 39.82429285 });
    const [mapZoom, setMapZoom] = useState(9);
    const [boundsNW, setBoundsNW] = useState('');
    const [boundsSE, setBoundsSE] = useState('');

    const [areasList, setAreasList] = useState([]);

    const [filter, setFilter] = useState({
        nw: boundsNW,
        se: boundsSE,
    });

    const [checkedHotel, setCheckedHotel] = useState(false);
    const [checkedBuilding, setCheckedBuilding] = useState(false);
    const [checkedHousing, setCheckedHousing] = useState(false);

    const [checkedStar, setCheckedStar] = useState(false);
    const [checked2Star, setChecked2Star] = useState(false);
    const [checked3Star, setChecked3Star] = useState(false);
    const [checked4Star, setChecked4Star] = useState(false);
    const [checked5Star, setChecked5Star] = useState(false);

    const [checkedFinalStar, setCheckedFinalStar] = useState([]);
    const [checkedFinalType, setCheckedFinalType] = useState([]);

    const [distanceFHaram, setDistanceFHaram] = useState('all');

    const [hotelSelected, setHotelSelected] = useState(null);

    useEffect(() => {
        setHotelSelected(null);
        onFetchHotelsMap(filter);
    }, [filter, lang]);

    useEffect(() => {
        if (firstRenderMap.current) {
            onFetchHotelsMap({
                nw: boundsNW,
                se: boundsSE,
            });
            console.log('filter', {
                nw: boundsNW,
                se: boundsSE,
            });
        }
        firstRenderMap.current = true;

    }, [boundsNW, boundsSE]);

    useEffect(() => {
        onFetchCities('966');
    }, [lang]);

    useEffect(() => {
        if (firstRender.current) {
            setAreasList(areas);
        }
        firstRender.current = true;
    }, [areas]);

    const searchHandler = (prop) => (event) => {
        console.log(prop, event.target.value);
        setFilter({ ...filter, "search": event.target.value });
        setSearchState(event.target.value)
    };


    const checkedHotelChangeHandler = (event) => {
        setCheckedHotel(!checkedHotel);

        let typeArr = checkedFinalType;
        let index = checkedFinalType.indexOf(1);
        if (index > -1) {
            typeArr.splice(index, 1);
        } else {
            typeArr.push(1);
        }

        setCheckedFinalType(typeArr);

        checkedFinalTypeChangeHandler();
    };

    const checkedBuildingChangeHandler = (event) => {
        setCheckedBuilding(!checkedBuilding);

        let typeArr = checkedFinalType;
        let index = checkedFinalType.indexOf(2);
        if (index > -1) {
            typeArr.splice(index, 1);
        } else {
            typeArr.push(2);
        }

        setCheckedFinalType(typeArr);

        checkedFinalTypeChangeHandler();
    };

    const checkedHousingChangeHandler = (event) => {
        setCheckedHousing(!checkedHousing);

        let typeArr = checkedFinalType;
        let index = checkedFinalType.indexOf(3);
        if (index > -1) {
            typeArr.splice(index, 1);
        } else {
            typeArr.push(3);
        }

        setCheckedFinalType(typeArr);

        checkedFinalTypeChangeHandler();
    };

    const checkedFinalTypeChangeHandler = () => {
        setFilter({ ...filter, "type": checkedFinalType });
    }

    const checkedFinalStarChangeHandler = () => {
        setFilter({ ...filter, "stars": checkedFinalStar });
        console.log('checkedFinalStar', checkedFinalStar);
    }

    const checkedStarChangeHandler = (event) => {
        setCheckedStar(!checkedStar);

        let starArr = checkedFinalStar;
        let index = checkedFinalStar.indexOf(1);
        if (index > -1) {
            starArr.splice(index, 1);
        } else {
            starArr.push(1);
        }

        setCheckedFinalStar(starArr);

        checkedFinalStarChangeHandler();
    };

    const checked2StarChangeHandler = (event) => {
        setChecked2Star(!checked2Star);

        let starArr = checkedFinalStar;
        let index = checkedFinalStar.indexOf(2);
        if (index > -1) {
            starArr.splice(index, 1);
        } else {
            starArr.push(2);
        }

        setCheckedFinalStar(starArr);

        checkedFinalStarChangeHandler();
    };

    const checked3StarChangeHandler = (event) => {
        setChecked3Star(!checked3Star);

        let starArr = checkedFinalStar;
        let index = checkedFinalStar.indexOf(3);
        if (index > -1) {
            starArr.splice(index, 1);
        } else {
            starArr.push(3);
        }

        setCheckedFinalStar(starArr);

        checkedFinalStarChangeHandler();
    };

    const checked4StarChangeHandler = (event) => {
        setChecked4Star(!checked4Star);

        let starArr = checkedFinalStar;
        let index = checkedFinalStar.indexOf(4);
        if (index > -1) {
            starArr.splice(index, 1);
        } else {
            starArr.push(4);
        }

        setCheckedFinalStar(starArr);

        checkedFinalStarChangeHandler();
    };

    const checked5StarChangeHandler = (event) => {
        setChecked5Star(!checked5Star);

        let starArr = checkedFinalStar;
        let index = checkedFinalStar.indexOf(5);
        if (index > -1) {
            starArr.splice(index, 1);
        } else {
            starArr.push(5);
        }

        setCheckedFinalStar(starArr);

        checkedFinalStarChangeHandler();
    };

    const distanceFHaramChangeHandler = (event, key) => {
        setDistanceFHaram(key);
        setFilter({ ...filter, 'distance': key });
    }

    const handleChangeCity = (event) => {
        setCity(event.target.value.target.value);
        onFetchAreas(event.target.value.target.value);
        setFilter({ ...filter, 'city': event.target.value.target.value });
    };

    const handleChangeAreas = (event) => {
        setArea(event.target.value.target.value);
        setFilter({ ...filter, 'area': event.target.value.target.value });
    };

    const resetHandleChange = (event) => {
        setSearchState('');
        setCity('');
        setArea('');

        setAreasList([]);

        setCheckedStar(false);
        setChecked2Star(false);
        setChecked3Star(false);
        setChecked4Star(false);
        setChecked5Star(false);

        setCheckedHotel(false);
        setCheckedBuilding(false);
        setCheckedHousing(false);

        setFilter({
            nw: "",
            se: "",
        })
    }

    const hotelHandlerClick = (Hotel) => {
        setHotelSelected(Hotel);
    }

    return (
        <div className="HotelsMap-Container">
            <div className="HotelsMap-Content">
                <div className="HotelsMap-FilterContainer">
                    <div className="HotelsMap-FilterContent">
                        <div className="HotelsMap-FilterHead">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterHeadTitle">{language.Hotels.hotels_filterBy}</Typography>
                            <div onClick={resetHandleChange} className={`HotelsMap-ResetContainer`}>
                                <RefreshIcon className="HotelsMap-ResetIcon" />
                                <Typography component={'span'} variant={'body2'} className="HotelsMap-ResetTitle">{language.Hotels.hotels_resetFilter}</Typography>
                            </div>
                        </div>
                        <div className="HotelsMap-FilterSearch">
                            <TextField
                                className={`HotelsMap-Input`}
                                id="search"
                                name="search"
                                label={<div>
                                    {language.Hotels.hotels_search}
                                </div>}
                                variant="outlined"
                                value={searchState}
                                onChange={searchHandler()}
                                placeholder={language.Hotels.hotels_search} />
                        </div>
                        <div className="HotelsMap-FilterFacilityType">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterFacilityTypeTitle">{language.Hotels.hotels_facility_type}</Typography>
                            <FormControl className="HotelsMap-FilterFacilityType-Checkbox">
                                <FormControlLabel
                                    className="HotelsMap-FilterFacilityType-Label"
                                    control={<Checkbox checked={checkedHotel} onChange={checkedHotelChangeHandler} name="hotel" />}
                                    label={language.Hotels.hotels_hotel}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterFacilityType-Label"
                                    control={<Checkbox checked={checkedBuilding} onChange={checkedBuildingChangeHandler} name="building" />}
                                    label={language.Hotels.hotels_building}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterFacilityType-Label"
                                    control={<Checkbox checked={checkedHousing} onChange={checkedHousingChangeHandler} name="housing" />}
                                    label={language.Hotels.hotels_furnished_housing}
                                />
                            </FormControl>
                        </div>
                        <div className="HotelsMap-FilterCity">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterCityTitle">{language.Hotels.hotels_city}</Typography>
                            <FormControl
                                className={`HotelsMap-Input HotelsMap-SelectInput`}
                                variant="outlined" >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    <div>
                                        {language.Hotels.hotels_city}
                                    </div>
                                </InputLabel>
                                <Select
                                    dir={styleDir}
                                    lang={lang}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={city}
                                    name="city"
                                    onChange={selectedOption => {
                                        let event = { target: { name: 'city', value: selectedOption } }
                                        handleChangeCity(event);
                                    }}
                                    label={<div>
                                        {language.Hotels.hotels_city}
                                    </div>}
                                >
                                    <MenuItem className="HotelsMap-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                        {language.Hotels.hotels_city}
                                    </MenuItem>
                                    {cities.map(city => (<MenuItem key={city.id} className="HotelsMap-MenuItem" dir={styleDir} lang={lang} value={city.id}>{city.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="HotelsMap-FilterArea">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterAreaTitle">{language.Hotels.hotels_area}</Typography>
                            <FormControl
                                className={`HotelsMap-Input HotelsMap-SelectInput`}
                                variant="outlined" >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    <div>
                                        {language.Hotels.hotels_areas}
                                    </div>
                                </InputLabel>
                                <Select
                                    dir={styleDir}
                                    lang={lang}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={area}
                                    name="areas"
                                    onChange={selectedOption => {
                                        let event = { target: { name: 'areas', value: selectedOption } }
                                        handleChangeAreas(event);
                                    }}
                                    label={<div>
                                        {language.Hotels.hotels_areas}
                                    </div>}
                                >
                                    <MenuItem className="HotelsMap-MenuItemDisabled" dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                        {language.Hotels.hotels_areas}
                                    </MenuItem>
                                    {areasList.map(area => (<MenuItem key={area.id} className="HotelsMap-MenuItem" dir={styleDir} lang={lang} value={area.id}>{area.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="HotelsMap-FilterStarRating">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterStarRatingTitle">{language.Hotels.hotels_starRating}</Typography>
                            <FormControl className="HotelsMap-FilterStarRating-Checkbox">
                                <FormControlLabel
                                    className="HotelsMap-FilterStarRating-Label"
                                    control={<Checkbox checked={checkedStar} onChange={checkedStarChangeHandler} name="star" />}
                                    label={language.Hotels.hotels_star}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterStarRating-Label"
                                    control={<Checkbox checked={checked2Star} onChange={checked2StarChangeHandler} name="2star" />}
                                    label={language.Hotels.hotels_2stars}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterStarRating-Label"
                                    control={<Checkbox checked={checked3Star} onChange={checked3StarChangeHandler} name="3star" />}
                                    label={language.Hotels.hotels_3stars}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterStarRating-Label"
                                    control={<Checkbox checked={checked4Star} onChange={checked4StarChangeHandler} name="4star" />}
                                    label={language.Hotels.hotels_4stars}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterStarRating-Label"
                                    control={<Checkbox checked={checked5Star} onChange={checked5StarChangeHandler} name="5star" />}
                                    label={language.Hotels.hotels_5stars}
                                />
                            </FormControl>

                        </div>
                        <div className="HotelsMap-FilterDistanceFHaram">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-FilterDistanceFHaramTitle">{language.Hotels.hotels_distanceFromHaram}</Typography>

                            <FormControl className="HotelsMap-FilterDistanceFHaram-Checkbox">
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === 'all'} onChange={(event, key) => distanceFHaramChangeHandler(event, 'all')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_all}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '0'} onChange={(event, key) => distanceFHaramChangeHandler(event, '0')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from0to500}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '1'} onChange={(event, key) => distanceFHaramChangeHandler(event, '1')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from500to1000}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '2'} onChange={(event, key) => distanceFHaramChangeHandler(event, '2')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from1000to2000}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '3'} onChange={(event, key) => distanceFHaramChangeHandler(event, '3')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from2000to3000}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '4'} onChange={(event, key) => distanceFHaramChangeHandler(event, '4')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from3000to4000}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '5'} onChange={(event, key) => distanceFHaramChangeHandler(event, '5')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from4000to5000}
                                />
                                <FormControlLabel
                                    className="HotelsMap-FilterDistanceFHaram-Label"
                                    control={<Checkbox checked={distanceFHaram === '6'} onChange={(event, key) => distanceFHaramChangeHandler(event, '6')} name="distanceFHaram" />}
                                    label={language.Hotels.hotels_from5000}
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="HotelsMap-ListContainer">
                    <div className="HotelsMap-ListContent">
                        <div className="HotelsMap-ListHead">
                            <Typography component={'span'} variant={'body2'} className="HotelsMap-ListHeadTitle">{hotels.length + ' ' + language.Hotels.hotels_hotel}</Typography>
                        </div>
                        <div className="HotelsMap-Lists">
                            {hotels.map(hotel => (
                                <div onClick={(Hotel) => hotelHandlerClick(hotel)} key={hotel.id} className="HotelsMap-HotelContainer">
                                    <div className="HotelsMap-HotelImg">
                                        <Skeleton variant="rect" width={90} height={115} />
                                    </div>
                                    <div className="HotelsMap-HotelContent">
                                        <Typography component={'span'} variant={'body2'} className="HotelsMap-HotelContent-Title">{hotel.facility_name}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="HotelsMap-HotelContent-LocationTitle">
                                            <LocationOnIcon />
                                            {hotel.city} / {hotel.area}
                                        </Typography>
                                        <Rating name="read-only" value={hotel.stars_value} readOnly max={5} />
                                        <Typography component={'span'} variant={'body2'} className="HotelsMap-HotelContent-DistanceTitle">
                                            {language.Hotels.hotels_distanceFromHaram} <span className="HotelsMap-HotelContent-DistanceValue">{hotel.harm_distance}</span>
                                        </Typography>
                                    </div>
                                    <div className="HotelsMap-HotelAction">
                                        <ArrowForwardIosIcon />
                                    </div>
                                </div>
                            ))}

                            {hotels.length === 0
                                ? <Typography component={'span'} variant={'body2'} className={"HotelsMap-NoData"}>{language.all.all_no_results}</Typography>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className="HotelsMap-MapContainer">
                    {loading
                        ? <Spinner />
                        : <GoogleMap language={language} Hotels={hotels} HotelSelected={hotelSelected} SetBoundsNW={setBoundsNW} SetBoundsSE={setBoundsSE} MapDefaultCenter={mapDefaultCenter} SetMapZoom={setMapZoom} MapZoom={mapZoom} SetMapdefaultCenter={setMapdefaultCenter} />
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        hotels: state.hotels.hotelsMap,
        loading: state.hotels.loading,
        cities: state.cities.cities,
        areas: state.areas.areas,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchHotelsMap: (filter) => dispatch(fetchHotelsMap(filter)),
        onFetchCities: (countryID) => dispatch(fetchCitiesWithCountry(countryID)),
        onFetchAreas: (payload) => dispatch(fetchAreas(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelsMap);