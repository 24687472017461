import React, { useState } from 'react';
import { connect } from "react-redux";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import './Snackbars.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbars = (props) => {
    const { language, openSnack, message, status, hideDuration, statusCode, errors } = props;

    const [open, setOpen] = useState(openSnack);
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    
    return(
        <Snackbar className="SnackBars-Container" anchorOrigin={{vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={hideDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={status}>
        <Typography component={'span'} variant={'body2'} className="SnackbarMessage">{message}</Typography>
          {statusCode === 422
            ? (errors
              ? Object.values(errors)?.map(item => {
                console.log(item)
                return item?.map(error => {
                  console.log(error);
                  return (<React.Fragment>
                    <br />
                      <Typography component={'span'} variant={'body2'} className="SnackbarMessage">{error}</Typography>
                  </React.Fragment>);
                })
              })
              : null)
            : null
          }
        </Alert>
      </Snackbar>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang
    };
};

export default connect(mapStateToProps)(Snackbars);